import React, { useContext } from "react";
import { useCss, k } from "kremling";

const CpRadioContext = React.createContext();

function CpRadio(props) {
  const { value, onChange } = props;
  return (
    <CpRadioContext.Provider value={{ value, onChange }}>
      <div {...useCss(css)} className="cp-radio">
        {props.children}
      </div>
    </CpRadioContext.Provider>
  );
}

function Item(props) {
  const { value, onChange } = useContext(CpRadioContext);
  const isSelected = props.value === value;
  return (
    <>
      <label
        htmlFor={`cp-radio-item-${props.value}`}
        className={`${isSelected ? "selected" : ""}`}
      >
        <span className={`cp-radio-circle`} />
        <span className="cp-radio-interior-circle" />
        {props.label}
        <input
          id={`cp-radio-item-${props.value}`}
          type="radio"
          value={props.value}
          name="alternate-firm"
          onChange={(e) => onChange(e.target.value)}
        />
      </label>
    </>
  );
}

CpRadio.Item = Item;

export default CpRadio;

// this CSS is based on Canopy's cps-radio with input and span
// it was somewhat of a learning experience/experiment and was not hardened for the future because this is not planned to be reused atm
// if it is re-used, I'd consult w3's custom radio button css and other sources for better ways to style this
// consider not having two circles but changing button border (size as well?) on check
// consider doing a span::after for the inner circle
// consider modernizing Canopy's cps-radio and making it re-usable

const css = k`
  .cp-radio {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  label {
    min-height: 50px;
    width: 100%;
    max-width: 300px;
    border-radius: 6px;
    border: 2px solid var(--cps-color-nav-label);
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    font-size: 2.0rem;
    padding: 16px 20px;
  }
  label input {
    opacity: 0;
    position: absolute;
  }
  .cp-radio-circle {
    border-radius: 0.8rem;
    border: 0.2rem solid var(--cps-color-nav-label);
    width: 1.6rem;
    height: 1.6rem;
    transition: 0.15s ease-in background-color;
    margin-right: 20px;
    flex-shrink: 0;
  }
  .cp-radio-interior-circle {
    border-radius: 0.8rem;
    width: 0.6rem;
    height: 0.6rem;
    position: absolute;
    margin-left: 5px;
    background-color: var(--cps-color-light-well);
  }
  label input[type="radio"]:checked + span {
    background-color: var(--cps-color-primary);
  }
  .selected {
    background-color: var(--cps-color-pill-bg);
    border-color: var(--cps-color-primary);
  }
  .selected .cp-radio-circle {
    background-color: var(--cps-color-primary);
    border-color: var(--cps-color-primary);
  }
`;
