import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { formatNumber, removeExtension } from "@utils/number/number.helper";
import { useMediaQuery } from "@hooks/use-media-query.hook";

export default function CompanyInfo(props) {
  const { tenant } = props;
  const phoneOnly = useMediaQuery("--phone-only");

  const someAttributeExists = () =>
    tenant.email ||
    tenant.address_1 ||
    tenant.address_2 ||
    tenant.phone_number ||
    tenant.fax_Number;
  const invalidRegion = tenant.region?.includes("Select");

  return (
    <div {...useCss(css)}>
      {tenant && someAttributeExists() && (
        <div className="company-info cps-color-secondary-text cps-bg-color-medium-well">
          {tenant.email && (
            <div>
              <CpIcon name="communication-envelope" />
              <a href={`mailto: ${tenant.email}`} className="cps-ellipsis text">
                {tenant.email}
              </a>
            </div>
          )}
          {tenant.address_1 && (
            <div>
              <CpIcon name="misc-map-pin" />
              <div className="text cps-ellipsis">
                {tenant.address_1}
                <br />
                {tenant.address_2 && (
                  <>
                    {tenant.address_2}
                    <br />
                  </>
                )}
                {tenant.locality
                  ? `${tenant.locality}${invalidRegion ? "" : ","} `
                  : ""}
                {invalidRegion ? "" : tenant.region} {tenant.postal_code}
              </div>
            </div>
          )}
          {tenant.phone_number && (
            <div>
              <CpIcon name="communication-phone" />
              {phoneOnly ? (
                <a href={`tel:${removeExtension(tenant.phone_number)}`}>
                  <div className="text">
                    {formatNumber(tenant.phone_number)}
                  </div>
                </a>
              ) : (
                <div className="text">{formatNumber(tenant.phone_number)}</div>
              )}
            </div>
          )}
          {tenant.fax_number && (
            <div>
              <CpIcon name="misc-printer" />
              <div className="text">
                fax {formatNumber(tenant.fax_number, true)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const css = k`
  .company-info {
    padding: 2rem 1.6rem;
    margin-bottom: 0;
  }

  .company-info > * {
    display: flex;
  }

  .company-info > * + * {
    margin: 1.6rem 0 .4rem;
  }

  .cp-icon {
    margin-right: 1rem;
    min-width: 2.4rem;
  }

  .text {
    margin-top: .2rem;
  }

`;
