import React from "react";
import { Match } from "@reach/router";
import { getBasePath } from "@utils/routing";

export default function FilesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "var(--cps-color-primary)" }}
    >
      <Match path={`${getBasePath()}/billing/*`}>
        {(props) =>
          props.match ? (
            <g fillRule="evenodd">
              <path
                fillOpacity=".3"
                d="M20,7 C20.5522847,7 21,7.44771525 21,8 L21,8 L21,16 C21,16.5522847 20.5522847,17 20,17 C19.4477153,17 19,16.5522847 19,16 L19,16 L19,8 C19,7.44771525 19.4477153,7 20,7 Z M14,5 L14,12 L7,12 L14,5 Z"
              />
              <path d="M12.5857864,4 L20,4 C22.1421954,4 23.8910789,5.68396847 23.9951047,7.80035966 L24,8 L24,16 L22,16 L22,8 C22,6.9456382 21.1841222,6.08183488 20.1492623,6.00548574 L20,6 L15,6 L15,11 C15,12.0543618 14.1841222,12.9181651 13.1492623,12.9945143 L13,13 L8,13 L8,23 C8,24.0543618 8.81587779,24.9181651 9.85073766,24.9945143 L10,25 L18,25 L18,27 L10,27 C7.85780461,27 6.10892112,25.3160315 6.00489531,23.1996403 L6,23 L6,10.5857864 L12.5857864,4 L20,4 Z M17,21 C17.5522847,21 18,21.4477153 18,22 C18,22.5522847 17.5522847,23 17,23 L11,23 C10.4477153,23 10,22.5522847 10,22 C10,21.4477153 10.4477153,21 11,21 L17,21 Z M17,18 C17.5522847,18 18,18.4477153 18,19 C18,19.5522847 17.5522847,20 17,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 L17,18 Z M17,15 C17.5522847,15 18,15.4477153 18,16 C18,16.5522847 17.5522847,17 17,17 L11,17 C10.4477153,17 10,16.5522847 10,16 C10,15.4477153 10.4477153,15 11,15 L17,15 Z M13,6.414 L8.414,11 L13,11 L13,6.414 Z" />
              <path d="M23.4,17.5 C23.7313708,17.5 24,17.7686292 24,18.1 L24,19 L25.4,19 C25.7313708,19 26,19.2686292 26,19.6 L26,20.4 C26,20.7313708 25.7313708,21 25.4,21 L22,21 L22,22 L25,22 C25.5522847,22 26,22.4477153 26,23 L25.999,23.017 L25.9998557,25.9828385 C25.9999518,25.9885475 26,25.9942681 26,26 C26,26.5522847 25.5522847,27 25,27 L24,27 L24,27.9 C24,28.2313708 23.7313708,28.5 23.4,28.5 L22.6,28.5 C22.2686292,28.5 22,28.2313708 22,27.9 L22,27 L20.6,27 C20.2686292,27 20,26.7313708 20,26.4 L20,25.6 C20,25.2686292 20.2686292,25 20.6,25 L23.999,25 L23.999,24 L21,24 C20.4477153,24 20,23.5522847 20,23 L20,20 C20,19.4477153 20.4477153,19 21,19 L22,19 L22,18.1 C22,17.7686292 22.2686292,17.5 22.6,17.5 L23.4,17.5 Z" />
            </g>
          ) : (
            <g fillRule="evenodd">
              <path
                style={{ fill: "var(--cps-color-af)" }}
                d="M12.5857864,4 L20,4 C22.1421954,4 23.8910789,5.68396847 23.9951047,7.80035966 L24,8 L24,16 L22,16 L22,8 C22,6.9456382 21.1841222,6.08183488 20.1492623,6.00548574 L20,6 L15,6 L15,11 C15,12.0543618 14.1841222,12.9181651 13.1492623,12.9945143 L13,13 L8,13 L8,23 C8,24.0543618 8.81587779,24.9181651 9.85073766,24.9945143 L10,25 L18,25 L18,27 L10,27 C7.85780461,27 6.10892112,25.3160315 6.00489531,23.1996403 L6,23 L6,10.5857864 L12.5857864,4 L20,4 Z M17,21 C17.5522847,21 18,21.4477153 18,22 C18,22.5522847 17.5522847,23 17,23 L11,23 C10.4477153,23 10,22.5522847 10,22 C10,21.4477153 10.4477153,21 11,21 L17,21 Z M17,18 C17.5522847,18 18,18.4477153 18,19 C18,19.5522847 17.5522847,20 17,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 L17,18 Z M17,15 C17.5522847,15 18,15.4477153 18,16 C18,16.5522847 17.5522847,17 17,17 L11,17 C10.4477153,17 10,16.5522847 10,16 C10,15.4477153 10.4477153,15 11,15 L17,15 Z M13,6.414 L8.414,11 L13,11 L13,6.414 Z"
              />
              <path
                style={{ fill: "var(--cps-color-af)" }}
                d="M23.4,17.5 C23.7313708,17.5 24,17.7686292 24,18.1 L24,19 L25.4,19 C25.7313708,19 26,19.2686292 26,19.6 L26,20.4 C26,20.7313708 25.7313708,21 25.4,21 L22,21 L22,22 L25,22 C25.5522847,22 26,22.4477153 26,23 L25.999,23.017 L25.9998557,25.9828385 C25.9999518,25.9885475 26,25.9942681 26,26 C26,26.5522847 25.5522847,27 25,27 L24,27 L24,27.9 C24,28.2313708 23.7313708,28.5 23.4,28.5 L22.6,28.5 C22.2686292,28.5 22,28.2313708 22,27.9 L22,27 L20.6,27 C20.2686292,27 20,26.7313708 20,26.4 L20,25.6 C20,25.2686292 20.2686292,25 20.6,25 L23.999,25 L23.999,24 L21,24 C20.4477153,24 20,23.5522847 20,23 L20,20 C20,19.4477153 20.4477153,19 21,19 L22,19 L22,18.1 C22,17.7686292 22.2686292,17.5 22.6,17.5 L23.4,17.5 Z"
              />
            </g>
          )
        }
      </Match>
    </svg>
  );
}
