import { useState, useEffect, useContext } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { catchError, map, pluck } from "rxjs/operators";
import { handleError } from "src/handle-error";
import { ClientContext } from "../client-portal.context.js";

export function useFilesApi() {
  const { selectedClient } = useContext(ClientContext);

  const [filesData, setFilesData] = useState({
    loading: true,
    files: [],
  });

  useEffect(() => {
    const filesFetcher = fetchAsObservable(
      `/api/docs/clients/${selectedClient.id}/folders/0/files?view=recent`
    ).pipe(pluck("folder_files"));
    filesFetcher.subscribe((files) => {
      setFilesData({
        loading: false,
        files,
      });
    }, handleError);
  }, [selectedClient.id]);

  return filesData;
}

export function searchDocs(folderId, query) {
  return fetchAsObservable(
    `/api/docs/search?search_term=${query}&folder_id=${folderId}`
  ).toPromise();
}

export function getClientDocs(folderId) {
  return fetchAsObservable(
    `/api/docs/folders/${folderId}/files?includes=name_path&view=client`
  ).toPromise();
}

export function markFileAsSeen(fileId) {
  return fetchAsObservable(`/api/docs/files/${fileId}:mark-seen`, {
    method: "POST",
  }).toPromise();
}

export function getClientNewFiles(clientId) {
  return fetchAsObservable(`/api/docs/clients/${clientId}/files-not-seen`)
    .pipe(pluck("files"))
    .toPromise();
}

export function createZip(fileIds) {
  return fetchAsObservable(`/api/docs/zips`, {
    method: "POST",
    body: { file_ids: fileIds },
  }).pipe(
    map((res) => res.hash),
    catchError(handleError)
  );
}

export function getZip(zipId) {
  return fetchAsObservable(`/api/docs/zips/${zipId}`);
}

export function getFileDownloadLink(fileId) {
  // GET /api/docs/files/:id/download
  return fetchAsObservable(
    `/api/docs/files/${fileId}/download?include_fields=true`
  );
}

export function renameClientFile(fileId, name) {
  return fetchAsObservable(`/api/docs/files/${fileId}`, {
    method: "PATCH",
    body: { file: { name: name } },
  });
}

export function deleteClientFile(fileId) {
  return fetchAsObservable(`/api/docs/files/${fileId}`, {
    method: "DELETE",
  });
}

export function hardDeleteClientFile(fileId) {
  return fetchAsObservable(`/api/docs/files:purge`, {
    method: "POST",
    body: { file_ids: [fileId], ignore_archived_requirement: true },
  });
}
