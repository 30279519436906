import React, { useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";
import { useCss, k } from "kremling";
import { CpLoader } from "canopy-styleguide!sofe";
import UserMessageCard from "src/not-found-pages/user-message-card.component";
import BalanceCard from "src/billing/balance-card/balance-card.component";
import { TodoListWrapper } from "src/todo/todo-list-wrapper.component";
import RecentFilesList from "src/files/recent-files-list.component";
import { useTodosApi } from "src/todo/todo.resource";
import { useFilesApi } from "src/files/files.resource";
import { ClientContext } from "../client-portal.context";
import { BillingContext } from "../billing/billing-context";

export default function Home({ enableBilling }) {
  const { selectedClient } = useContext(ClientContext);
  const { hasCanopyPayments, balance, showPaymentFlow, loadingBalance } =
    useContext(BillingContext);
  const { todos: tasks, loading: loadingTasks } = useTodosApi();
  const { files, loading: loadingFiles } = useFilesApi();
  const [loaded, setLoaded] = useState(false);
  const scope = useCss(css);

  useEffect(() => {
    if (loadingBalance || loadingTasks || loadingFiles) return;
    setLoaded(true);
  }, [loadingBalance, loadingTasks, loadingFiles]);

  return !loaded ? (
    <CpLoader />
  ) : isEmpty(tasks) && isEmpty(files) && balance === 0 ? (
    <>
      <UserMessageCard
        noLeftNav={true}
        img="es_thumbs_up"
        text="It looks like you’re new here"
        subText="You can get started by viewing or uploading a file on the files tab"
        addTopMenuPadding
      />
    </>
  ) : (
    <div {...scope} className="page-content no-footer">
      {enableBilling && (hasCanopyPayments || showPaymentFlow) && (
        <BalanceCard clientId={selectedClient.id} extended={true} />
      )}
      <div className="cps-subheader-sm cps-wt-bold header-label">To-do</div>
      <TodoListWrapper
        tasks={tasks}
        loadingTasks={loadingTasks}
        hideCompleted={true}
        maxItemsToShow={5}
        home={true}
      />
      <RecentFilesList
        maxItemsToShow={5}
        files={files}
        loading={loadingFiles}
      />
    </div>
  );
}

const css = k`
  .header-label {
    margin: 3.6rem 2.4rem 1.6rem 2.4rem;
  }

  .empty-state {
    margin-left: 26px;
    margin-right: 26px;
  }

  .practitioner-info-card {
    margin: 4.2rem 0 2.4rem 0;
  }

  .practitioner-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .practitioner-quick-action {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0px 8px;
    background-color: var(--cps-color-primary-extra-light);

    &:active {
      background-color: var(--cps-color-primary-dark);
    }
  }

  .settings-icon {
    margin: 8px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .info-wrapper {
    flex-direction: column;
  }

  .small-info {
    font-size: 12px;
    color: var(--cps-color-cool-gray);
  }

  hr {
    width: inherit;
    margin: 8px 4px 0px 4px;
    background-color: var(--cps-color-primary);
  }
`;
