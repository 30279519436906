import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export const getClientSurvey = ({ clientId, resolutionCaseId, surveyId }) =>
  fetchAsObservable(
    `/wg/clients/${clientId}/resolution_cases/${resolutionCaseId}/surveys/${surveyId}`
  ).pipe(pluck("surveys"));

export const getResolutionCase = ({ clientId, resolutionCaseId }) =>
  fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`
  ).pipe(pluck("resolution_cases"));
