import canopyUrls from "canopy-urls!sofe";

export function formatNumber(
  numberOrString,
  includeSymbol = true,
  precision = 0
) {
  const number = convertStringToFloat(numberOrString);
  return number.toLocaleString("en-US", {
    style: includeSymbol ? "currency" : "decimal",
    currency: "USD",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

export function convertStringToFloat(numberOrString) {
  const number = parseFloat(numberOrString);
  if (!isNaN(number)) {
    return number;
  } else {
    return 0;
  }
}

export const wholeNumberFilter = (value, allowLeadingZero = false) => {
  return value.startsWith("0") && !allowLeadingZero
    ? value.replace(/^0+/, "")
    : value.replace(/[^0-9]*/g, "");
};

export const isProd = () =>
  canopyUrls.getEnvironment() === canopyUrls.PRODUCTION_ENVIRONMENT;
