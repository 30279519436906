import React, { useContext } from "react";
import { useCss } from "kremling";
import { Link } from "@reach/router";
import css from "./account-menu-content.krem.css";

import { track } from "src/common/analytics.helper.js";
import { CpIcon, CpNameChip, CpBadge } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";

import { getResponsivePath } from "@utils/routing";
import { ClientContext } from "src/client-portal.context.js";

export default function AccountMenuContent(props) {
  const {
    clients,
    activeClient,
    hasCanopyPayments,
    entities,
    clientsTodos,
    closeMenu,
    customLinks,
    companyWebsite,
    showDisplayName,
    showUserMenu,
  } = props;

  const { ft_crm } = useContext(ClientContext);

  const customLinksEnabled = customLinks?.length > 0;

  const inactiveClients = clients.filter(
    (client) => client.id !== activeClient?.id
  );

  return (
    <div {...useCss(css)} className="cp-select-list cp-ellipsis">
      {inactiveClients.map((client) => {
        let clientTodos = clientsTodos?.[client.id];
        const name = ft_crm
          ? client?.external_display_name
          : showDisplayName && client.display_name
          ? client.display_name
          : client.name;

        return (
          <Link
            className="cp-ellipsis inactive-client-link"
            to={`${getResponsivePath()}clients/${client.id}`}
            onClick={closeMenu}
            key={client.id}
          >
            {!ft_crm && <CpNameChip className="cp-mr-16" name={name} />}
            <span className="cp-ellipsis cp-mr-8">{name}</span>
            {!!clientTodos && (
              <CpBadge className="todos-badge" intent="number">
                {clientTodos > 99 ? "99+" : clientTodos}
              </CpBadge>
            )}
          </Link>
        );
      })}
      {inactiveClients.length > 0 && <hr className="white-label-borders" />}
      {(customLinksEnabled || companyWebsite?.enabled) && (
        <>
          {companyWebsite?.enabled && (
            <a
              href={companyWebsite.link}
              target="_blank"
              rel="noopener noreferrer"
              className="menu-row cp-ellipsis text"
              onClick={() =>
                track("third-party-link_clicked", { linkClicked: "Website" })
              }
            >
              <CpIcon className="menu-icon" name="misc-globe" />
              <span className="cp-ellipsis">{companyWebsite.link}</span>
            </a>
          )}
          {customLinksEnabled &&
            customLinks.map(({ link, link_label }) => (
              <a
                className="menu-row"
                href={link}
                key={link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  track("third-party-link_clicked", { linkClicked: "Custom" })
                }
              >
                <CpIcon className="menu-icon" name="af-external-link" />
                <span className="cp-ellipsis">{link_label || link}</span>
              </a>
            ))}
          <hr className="white-label-borders" />
        </>
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.clientportal.com/"
        className="menu-row"
      >
        <CpIcon name="help-circle-open-large" className="menu-icon" />
        Support Library
      </a>
      {activeClient && (
        <Link to="settings" className="menu-row" onClick={closeMenu}>
          <CpIcon name="misc-gear" className="menu-icon" />
          User settings
        </Link>
      )}
      {props.enableBilling && hasCanopyPayments && activeClient && (
        <Link to="payment-settings" className="menu-row" onClick={closeMenu}>
          <CpIcon name="billing-circle-open" className="menu-icon" />
          Payment settings
        </Link>
      )}
      {entities?.length > 1 && (
        <button onClick={showUserMenu} className="menu-row">
          <CpIcon name="af-transfer" className="menu-icon" />
          <div className="cp-select-list__content">
            <div className="cp-body">Switch Firm</div>
          </div>
          <CpIcon
            className="cp-select-list__icon-right"
            name="caret-large-right"
          />
        </button>
      )}
      <a
        href={`${canopyUrls.getWebUrl()}${getResponsivePath()}logout?redirect_url=${canopyUrls.getWebUrl()}`}
        className="menu-row"
      >
        <CpIcon name="af-sign-out" className="menu-icon" />
        Sign out
      </a>
    </div>
  );
}
