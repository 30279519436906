import React from "react";
import { Match } from "@reach/router";
import { getBasePath } from "@utils/routing";

export default function TodoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "var(--cps-color-primary)" }}
    >
      <Match path={`${getBasePath()}/todo/*`}>
        {(props) =>
          props.match ? (
            <g fillRule="evenodd" transform="translate(6 4)">
              <path
                fillOpacity=".3"
                d="M2,6 L2,3 L19,3 L19,6 L15,6 C15,6.55228475 14.5522847,7 14,7 L7,7 C6.44771525,7 6,6.55228475 6,6 L2,6 Z"
              />
              <path
                fillRule="nonzero"
                d="M14,0 C14.5522847,0 15,0.44771525 15,1 L19,1 C20.1045695,1 21,1.8954305 21,3 L21,23 C21,24.1045695 20.1045695,25 19,25 L2,25 C0.8954305,25 0,24.1045695 0,23 L0,3 C0,1.8954305 0.8954305,1 2,1 L6,1 C6,0.44771525 6.44771525,0 7,0 L14,0 Z M6,3 L2,3 L2,23 L19,23 L19,3 L15,3 L15,4 C15,4.55228475 14.5522847,5 14,5 L7,5 C6.44771525,5 6,4.55228475 6,4 L6,3 Z M16,18 C16.5522847,18 17,18.4477153 17,19 C17,19.5522847 16.5522847,20 16,20 L9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 L16,18 Z M5,18 C5.55228475,18 6,18.4477153 6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 C4,18.4477153 4.44771525,18 5,18 Z M16,13 C16.5522847,13 17,13.4477153 17,14 C17,14.5522847 16.5522847,15 16,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L16,13 Z M5,13 C5.55228475,13 6,13.4477153 6,14 C6,14.5522847 5.55228475,15 5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 Z M8.70710678,7.79289322 C9.06759074,8.15337718 9.09532028,8.72060824 8.79029539,9.11289944 L8.70710678,9.20710678 L6.70710678,11.2071068 C6.34662282,11.5675907 5.77939176,11.5953203 5.38710056,11.2902954 L5.29289322,11.2071068 L4.29289322,10.2071068 C3.90236893,9.81658249 3.90236893,9.18341751 4.29289322,8.79289322 C4.65337718,8.43240926 5.22060824,8.40467972 5.61289944,8.70970461 L5.70710678,8.79289322 L6,9.08578644 L7.29289322,7.79289322 C7.68341751,7.40236893 8.31658249,7.40236893 8.70710678,7.79289322 Z M16,8 C16.5522847,8 17,8.44771525 17,9 C17,9.55228475 16.5522847,10 16,10 L11,10 C10.4477153,10 10,9.55228475 10,9 C10,8.44771525 10.4477153,8 11,8 L16,8 Z M13,2 L8,2 L8,3 L13,3 L13,2 Z"
              />
            </g>
          ) : (
            <path
              style={{ fill: "var(--cps-color-af)" }}
              d="M14,0 C14.5522847,0 15,0.44771525 15,1 L19,1 C20.1045695,1 21,1.8954305 21,3 L21,23 C21,24.1045695 20.1045695,25 19,25 L2,25 C0.8954305,25 0,24.1045695 0,23 L0,3 C0,1.8954305 0.8954305,1 2,1 L6,1 C6,0.44771525 6.44771525,0 7,0 L14,0 Z M6,3 L2,3 L2,23 L19,23 L19,3 L15,3 L15,4 C15,4.55228475 14.5522847,5 14,5 L7,5 C6.44771525,5 6,4.55228475 6,4 L6,3 Z M16,18 C16.5522847,18 17,18.4477153 17,19 C17,19.5522847 16.5522847,20 16,20 L9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 L16,18 Z M5,18 C5.55228475,18 6,18.4477153 6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 C4,18.4477153 4.44771525,18 5,18 Z M16,13 C16.5522847,13 17,13.4477153 17,14 C17,14.5522847 16.5522847,15 16,15 L9,15 C8.44771525,15 8,14.5522847 8,14 C8,13.4477153 8.44771525,13 9,13 L16,13 Z M5,13 C5.55228475,13 6,13.4477153 6,14 C6,14.5522847 5.55228475,15 5,15 C4.44771525,15 4,14.5522847 4,14 C4,13.4477153 4.44771525,13 5,13 Z M16,8 C16.5522847,8 17,8.44771525 17,9 C17,9.55228475 16.5522847,10 16,10 L9,10 C8.44771525,10 8,9.55228475 8,9 C8,8.44771525 8.44771525,8 9,8 L16,8 Z M5,8 C5.55228475,8 6,8.44771525 6,9 C6,9.55228475 5.55228475,10 5,10 C4.44771525,10 4,9.55228475 4,9 C4,8.44771525 4.44771525,8 5,8 Z M13,2 L8,2 L8,3 L13,3 L13,2 Z"
              transform="translate(6 4)"
            />
          )
        }
      </Match>
    </svg>
  );
}
