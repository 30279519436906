import React, { Suspense } from "react";
import { noop } from "lodash";
import {
  CpCard,
  CpRadio,
  CpLoader,
  CpWell,
  CpCheckbox,
} from "canopy-styleguide!sofe";
import { PaymentFormCC } from "./payment-form-cc.component";
import { PaymentFormACH } from "./payment-form-ach.component";
import {
  isExpiredCard,
  AdyenPaymentForm,
  paymentViewMode,
} from "./create-edit-payment.helper";

export const CreateEditPaymentMethod = ({
  actions,
  hasAdyen,
  isEngagement,
  paymentDetails,
  show,
  tenant,
}) => {
  const {
    setAdyenInstance,
    setSavedMethod,
    toggleMakeDefault,
    setSavedMethodName,
  } = actions;
  const {
    paymentMethod,
    isFuturePayment,
    adyenSessionDetails,
    savedMethod,
    makeDefault,
    savedMethodName,
  } = paymentDetails;

  return (
    <div className="cp-mv-12">
      {paymentMethod.type.includes("new") && (
        <div className="cp-flex">
          <CpCard
            level={2}
            className={`cp-p-16 payment-method-card cp-mr-16 ${
              paymentMethod.type === "newCreditCard" ? "selected" : ""
            }`}
            onClick={() => {
              actions.updatePaymentMethod({ type: "newCreditCard" });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CpRadio
                name="creditCard"
                value={paymentMethod.type}
                onChange={noop}
              >
                <CpRadio.Item id="newCreditCard" />
              </CpRadio>
              Credit card
            </div>
          </CpCard>
          <CpCard
            level={2}
            className={`cp-p-16 payment-method-card ${
              paymentMethod.type === "newACH" ? "selected" : ""
            }`}
            onClick={() => {
              actions.updatePaymentMethod({ type: "newACH" });
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CpRadio name="ach" value={paymentMethod.type} onChange={noop}>
                <CpRadio.Item id="newACH" />
              </CpRadio>
              Bank account
            </div>
          </CpCard>
        </div>
      )}
      {!hasAdyen && isExpiredCard(paymentMethod) && (
        <div className="cp-mv-16 cps-wt-bold ">
          This credit card is expired. Please enter your new credit card
          information or select another payment method.
        </div>
      )}
      {hasAdyen && show && (
        <Suspense fallback={<CpLoader />}>
          <AdyenPaymentForm
            paymentMethod={paymentMethod}
            paymentType={paymentMethod.type}
            adyenSessionDetails={adyenSessionDetails}
            setAdyenInstance={setAdyenInstance}
            isFuturePayment={isFuturePayment}
            savedMethod={savedMethod}
            setSavedMethod={setSavedMethod}
            savedMethodName={savedMethodName}
            setSavedMethodName={setSavedMethodName}
            defaultMethod={makeDefault}
            setDefaultMethod={toggleMakeDefault}
            paymentViewMode={
              isEngagement ? paymentViewMode.Settings : paymentViewMode.Payments
            }
            isInternal={tenant.is_internal}
          />
          {savedMethod && !isEngagement && (
            <div className="cp-mt-24">
              <CpWell level={3} className="cp-p-16">
                <CpCheckbox
                  onChange={(isChecked) => actions.authorizeUse(isChecked)}
                  checked={paymentMethod.authorizedUse}
                >
                  <div className="cp-ml-12">
                    {`I authorize ${tenant.company_name} to use the ${
                      paymentMethod.type === "newCreditCard"
                        ? "credit card"
                        : "bank account"
                    } information above for future transactions.
                    I understand that the ${
                      paymentMethod.type === "newCreditCard"
                        ? "credit card"
                        : "banking information"
                    } provided above will be saved.`}
                  </div>
                </CpCheckbox>
              </CpWell>
            </div>
          )}
        </Suspense>
      )}
      {!hasAdyen &&
        (paymentMethod.type.includes("CreditCard") ? (
          <PaymentFormCC
            forceSave={paymentMethod.type.includes("saved") || isFuturePayment}
          />
        ) : (
          <PaymentFormACH forceSave={isFuturePayment} />
        ))}
    </div>
  );
};
