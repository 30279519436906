import React from "react";
import { useCss, k, a } from "kremling";
import { CpLoader, CpWell, CpCard } from "canopy-styleguide!sofe";
import { useMediaQuery } from "@hooks/use-media-query.hook";

export function TodoDetailsCard({
  headerContent,
  bodyContent,
  footerContent,
  loading,
  task,
}) {
  const styles = useCss(css);
  const phoneOnly = useMediaQuery("--phone-only");

  return (
    <CpCard level={2} {...styles} className="details-card">
      {loading ? (
        <div className="loader-container">
          <CpLoader size="lg" />
        </div>
      ) : (
        <>
          {headerContent}
          <CpWell level={2} className="body">
            {bodyContent}
          </CpWell>
          {footerContent && (
            <div className={a("footer").m("content-fixed", phoneOnly)}>
              {footerContent}
            </div>
          )}
        </>
      )}
    </CpCard>
  );
}

const css = k`
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
  }

  .details-card {
    position: relative;
    @media #{$--phone-only} {
      border: none;
      height: 100%;
    }
  }

  .body {
    border-radius: 0;
    border-left: none;
    border-right: none;
    @media #{$--phone-only} {
      height: 85%;
    }
  }

  .footer {
    background: white;
    padding: 1.6rem;
  }

  .content-fixed {
    position: fixed;
    bottom: 0;
    margin-left: 0;
    background-color: var(--cp-color-card-bg);
    border-top: solid .1rem var(--cp-color-card-border);
    padding: 2.4rem;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
      max-width: 40rem;
    }

    @media #{$--phone-only} {
      min-width: 100%;
    }
  }
`;
