import "./set-public-path.js";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import singleSpaCanopy from "single-spa-canopy";
import { fetchAsObservable } from "fetcher!sofe";
import { Root } from "./root.component";
import { Scoped } from "kremling";

Scoped.defaultNamespace = "client-portal";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  domElementGetter,
});

const canopyLifecycles = singleSpaCanopy({
  domElementGetter,
  childAppName: "client-portal",
});

export const bootstrap = [
  setupPaysafe,
  canopyLifecycles.bootstrap,
  reactLifecycles.bootstrap,
];

export const mount = [reactLifecycles.mount, canopyLifecycles.mount];

export const unmount = [reactLifecycles.unmount, canopyLifecycles.unmount];

export const unload = [canopyLifecycles.unload];

function domElementGetter() {
  let el = document.getElementById("client-app");
  if (!el) {
    el = document.createElement("div");
    el.id = "client-app";
    document.body.appendChild(el);
  }
  return el;
}

function setupPaysafe() {
  return new Promise((resolve, reject) => {
    if (window.paysafe) {
      resolve();
    }
    fetchAsObservable("/api/canopy_payments/status").subscribe((response) => {
      if (response.status.is_active) {
        var el = document.createElement("script");
        el.setAttribute(
          "src",
          `https://hosted.paysafe.com/js/v1/latest/paysafe.min.js`
        );
        el.setAttribute("type", "text/javascript");
        var head = document.getElementsByTagName("head")[0];

        try {
          head.appendChild(el);
          resolve();
        } catch (e) {
          reject(e);
        }
      } else {
        resolve();
      }
    });
  });
}

export const loadRenameFileModal = () =>
  import(
    /* webpackChunkName: "rename-file-modal" */ "src/files/rename-file-modal.component.js"
  ).then((mod) => mod);

export const loadItemComponent = () =>
  import(
    /* webpackChunkName: "item-component" */ "src/files/item.component.js"
  ).then((mod) => mod);

export const getPaymentDetails = () =>
  import(
    /* webpackChunkName: "payment-details" */ "src/billing/create-edit-payment/payment-details.component.js"
  ).then((mod) => mod);
