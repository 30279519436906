import React from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

export const CvvModal = ({ show, onClose, amexEnabled }) => (
  <CpModal {...{ show, onClose }}>
    <CpModal.Header title="Where to find the CVV" />
    <CpModal.Body>
      <div className="cp-p-8">
        <div className="cp-flex">
          <img
            src="https://cdn.canopytax.com/images/cvv-example-3digit.svg"
            height={29}
          />
          <div className="cp-ml-16">
            The 3-digit number for Visa, Mastercard, or Discover is located on
            the back of the card next to the signature panel.
          </div>
        </div>
        {amexEnabled && (
          <div className="cp-mt-16 cp-flex">
            <img
              src="https://cdn.canopytax.com/images/cvv-example-4digit.svg"
              height={29}
            />
            <div className="cp-ml-16">
              The 4-digit number for American Express is located on the front of
              the card above the card number.
            </div>
          </div>
        )}
        <CpButton
          onClick={onClose}
          block
          className="cp-mt-24"
          style={{ height: 48 }}
        >
          Done
        </CpButton>
      </div>
    </CpModal.Body>
  </CpModal>
);
