import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function fetchEntities() {
  return fetchAsObservable(`/wg/entities?role=Client`).pipe(pluck("users"));
}

export function fetchClients({
  filters = {
    is_active: [{ equal_to: true }],
    is_archived: [{ equal_to: false }],
  },
  limit = "50",
  page = 1,
  search = "",
  visible_columns = ["external_display_name"],
  ft_crm,
}) {
  if (ft_crm) {
    return fetchAsObservable(`/api/v2/clients/search`, {
      method: "POST",
      body: {
        filters,
        limit,
        page,
        search,
        visible_columns,
      },
    }).pipe(pluck("clients"));
  } else {
    let jql = [
      {
        field: "is_archived",
        operator: "eq",
        value: false,
      },
      {
        field: "is_active",
        operator: "eq",
        value: true,
      },
    ];
    return fetchAsObservable(
      `/api/clients?jql=${encodeURIComponent(JSON.stringify(jql))}&portal=1`
    ).pipe(pluck("clients"));
  }
}
