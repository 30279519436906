import React, { lazy, Suspense } from "react";
import { Router } from "@reach/router";

const BillingRoot = lazy(() =>
  import(/* webpackChunkName: 'BillingRoot' */ "./billing-root.component")
);
const Invoice = lazy(() =>
  import(/* webpackChunkName: 'Invoice' */ "./invoices/invoice.component")
);
const Credit = lazy(() =>
  import(/* webpackChunkName: 'Credit' */ "./credits/credit.component")
);
const PaymentReceipt = lazy(() =>
  import(
    /* webpackChunkName: 'Payment-Receipt' */ "./payment-history/payment-receipt.component"
  )
);
const CreateEditPayment = lazy(() =>
  import(
    /* webpackChunkName: 'Create-Edit-Payment' */ "./create-edit-payment/create-edit-payment.component"
  )
);

export default function BillingRouter(props) {
  const { selectedClient } = props;

  return (
    <Suspense fallback={null}>
      <Router>
        <BillingRoot path="/*" selectedClient={selectedClient} />
        <Invoice path="/invoice/:invoiceId" selectedClient={selectedClient} />
        <Credit path="/credit/:creditId" selectedClient={selectedClient} />
        <PaymentReceipt path="/payment/:paymentId" />
        <CreateEditPayment
          path="/payment/create"
          clientId={selectedClient.id}
          invoiceId={props.location?.state?.invoiceId}
        />
        <CreateEditPayment
          path="/payment/edit/:paymentId"
          clientId={selectedClient.id}
        />
      </Router>
    </Suspense>
  );
}
