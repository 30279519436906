import React, { Suspense, useState, useContext, useEffect } from "react";
import { useCss, k } from "kremling";
import { map } from "lodash";
import { captureException } from "error-logging!sofe";

import { Preview } from "docs-ui!sofe";
import { CpEmptyState, CpLoader } from "canopy-styleguide!sofe";

import { ClientContext } from "../client-portal.context.js";

import { RecentFile } from "src/files/recent-file.component";
import { getClientNewFiles, markFileAsSeen } from "./files.resource";

export default function RecentFilesList(props) {
  const scope = useCss(css);
  const { selectedClient } = useContext(ClientContext);
  const [selectedFile, setSelectedFile] = useState();
  const [previewFile, setPreviewFile] = useState(null);
  const [newFiles, setNewFiles] = useState([]);
  const { maxItemsToShow, files, loading } = props;

  useEffect(() => {
    (async function () {
      if (selectedClient) {
        try {
          const _newFiles = await getClientNewFiles(selectedClient.id);
          setNewFiles(_newFiles);
        } catch (e) {
          captureException(e);
        }
      }
    })();
  }, [selectedClient]);

  async function markAsSeen(file) {
    try {
      await markFileAsSeen(file.id);
      const _newFiles = await getClientNewFiles(selectedClient.id);
      setNewFiles(_newFiles);
    } catch (e) {
      captureException(e);
    }
  }

  function displayPreview(file) {
    if (newFiles.map((file) => file.id).includes(file.id)) {
      markAsSeen(file);
    }
    setPreviewFile(file);
  }

  if (loading) {
    return <CpLoader />;
  }

  return (
    <div {...scope}>
      {selectedFile && (
        <Suspense fallback={<></>}>
          <Preview
            clientId={selectedClient.id}
            doc={selectedFile}
            handleClosePreview={() => setSelectedFile()}
            actions={["download"]}
            isClientPortal={true}
          />
        </Suspense>
      )}
      <div className="cps-subheader-sm cps-wt-bold header-label">
        Recent Files
      </div>
      {files.length === 0 && (
        <div className="cp-card-l2 empty-state cp-p-36">
          <CpEmptyState
            text="You don't have any recent files yet"
            img="folder-closed"
          />
        </div>
      )}
      <div className="recent-files cp-mh-24">
        {files.length > 0 &&
          map(files, (file, index) => {
            if (maxItemsToShow && index >= maxItemsToShow) {
              return null;
            } else {
              return (
                <div className="recent-files__item" key={file.id}>
                  <RecentFile
                    item={file}
                    onClick={() => displayPreview(file)}
                    setSelectedFile={setSelectedFile}
                    seenByClient={newFiles
                      .map((file) => file.id)
                      .includes(file.id)}
                  />
                </div>
              );
            }
          })}
      </div>
      {previewFile && (
        <Suspense fallback={null}>
          <Preview
            doc={previewFile}
            hideLeftSidePane
            actions={["download", "print"]}
            isClientPortal={true}
            handleClosePreview={() => {
              setPreviewFile(null);
            }}
          />
        </Suspense>
      )}
    </div>
  );
}

const css = k`

  .recent-files {
    padding-bottom: 8.0rem;
  }

  .recent-files__item {
    margin-bottom: 1.6rem;
  }
`;
