import React from "react";

export default function PaymentError({ error }) {
  return (
    <div>
      <p className="cp-mh-12">{error.subject}</p>
      <p className="cp-mh-32 cp-color-app-error-text">{error.details}</p>
    </div>
  );
}
