import React, { useEffect, useContext } from "react";
import { DateTime } from "luxon";
import { PaymentContext } from "../billing-context";
import { formatNumber } from "../billing-helpers";
import { getPaymentMethodName } from "./create-edit-payment.helper";

export default function PaymentReceipt() {
  const { paymentDetails } = useContext(PaymentContext);
  const {
    paymentTotal,
    invoices,
    isFuturePayment,
    isScheduledPayment,
    paymentDate,
    paymentMethod,
    paymentNumber,
    creditNumber,
  } = paymentDetails;

  const selectedInvoices = invoices?.filter((invoice) => invoice.selected);
  const scheduled = isFuturePayment || isScheduledPayment;

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("billing-ui::payment-complete"));
  }, []);

  return (
    <div className="cp-color-app-secondary-text cp-ph-16">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={`https://cdn.canopytax.com/static/empty-states/es_checkmark.svg`}
        />
        <div className="cps-subheader-sm cps-wt-semibold cp-pt-4">
          {scheduled
            ? "Your payment has been scheduled"
            : "Your payment was successful"}
        </div>
      </div>
      <div className="cp-pt-24">
        Total paid: {formatNumber(paymentTotal, true, 2)}
      </div>
      <div>Payment date: {paymentDate.toLocaleString(DateTime.DATE_MED)}</div>
      {selectedInvoices?.length > 0 && (
        <div>{`Invoice${
          selectedInvoices.length !== 1 ? "s" : ""
        } paid: ${selectedInvoices
          .map((invoice) => invoice.invoice_number)
          .join(", ")}`}</div>
      )}
      <div className="cp-flex">
        <div className="cp-mr-4">Payment method:</div>
        {getPaymentMethodName(paymentMethod)}
      </div>
      {!scheduled && <div>Payment number: {paymentNumber}</div>}
      {creditNumber && <div>Credit number: {creditNumber}</div>}
      <div className="cp-pt-8">
        A payment receipt will be emailed to you
        {scheduled ? " when the payment is completed" : " shortly"}
      </div>
    </div>
  );
}
