import React from "react";
import { DateTime } from "luxon";
import { useCss } from "kremling";
import { CpIcon } from "canopy-styleguide!sofe";
import { useSwitchUser } from "login-ui!sofe";
import css from "./switch-user-menu-content.krem.css";

export function SwitchUserMenuContent({ entities, user, closeUserMenu }) {
  const setSwitchUser = useSwitchUser();

  return (
    <div {...useCss(css)} className="cp-select-list cp-ellipsis">
      <button onClick={(e) => e.stopPropagation() || closeUserMenu()}>
        <CpIcon className="cp-select-list__icon-left" name="caret-large-left" />
        <div className="cp-body">Back</div>
      </button>
      {entities &&
        entities
          .filter((e) => {
            return e.id !== user.id;
          })
          .map((entity) => {
            const lastLogin = entity.lastLogin
              ? `${DateTime.fromMillis(user.lastLogin).toFormat("D")}`
              : "never";
            return (
              <button key={entity.id} onClick={() => setSwitchUser(entity)}>
                <div className="cp-select-list__content">
                  <div className="cp-body cp-ellipsis">
                    {entity.company_name}
                  </div>
                  <div className={`lineSecondaryText cp-caption cp-ellipsis`}>
                    Client Portal - Last Login {lastLogin}
                  </div>
                </div>
              </button>
            );
          })}
    </div>
  );
}
