import React from "react";
import { useCss, k } from "kremling";
import { getBadgeTextAndType, taskIsComplete } from "./todo-card.helper";

const badgeTypeToColor = {
  NEW: "var(--cp-color-app-primary)",
  OVERDUE: "var(--cp-color-notification-badge-bg)",
  REVIEW: "#ff8a65",
};

export function StatusBadge({ task }) {
  const styles = useCss(css);
  const isComplete = taskIsComplete(task.status);
  const { badgeText, badgeType } = getBadgeTextAndType({
    dueDate: task.due_at,
    viewedAt: task.last_viewed_at || task.viewed_at,
    status: task.status,
  });

  if (!task) return null;
  return (
    <>
      {!isComplete && badgeType !== "UNMARKED" && (
        <div
          {...styles}
          className="task-status"
          style={{
            backgroundColor:
              badgeTypeToColor[badgeType] || badgeTypeToColor.info,
          }}
        >
          {badgeText}
        </div>
      )}
    </>
  );
}

const css = k`
  .task-status {
    position: absolute;
    height: 20px;
    width: 72px;
    top: -8px;
    right: -6px;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    background: var(--cp-color-notification-badge-bg);
    color: var(--cp-color-notification-badge-text);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
`;
