import React, { useEffect } from "react";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { useCss, k } from "kremling";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { updateSurchargeOptions } from "./create-edit-payment/create-edit-payment.helper";
import { handleError } from "src/handle-error";

export default function SurchargeDisclosure({
  show,
  onClose,
  surchargeViewed,
  surchargeFeeText,
}) {
  const phoneOnly = useMediaQuery("--phone-only");
  const scope = useCss(css);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "visible");
  });

  const closeModal = () => {
    if (!surchargeViewed) {
      updateSurchargeOptions({ viewed: true }).subscribe(() => {
        onClose();
      }, handleError);
      return;
    }
    onClose();
  };

  return phoneOnly ? (
    <div
      {...scope}
      className="cp-flex-center cp-ph-24 surcharge-disclosure-overlay"
      style={{ justifyContent: "center", flexDirection: "column" }}
    >
      <h1 className="surcharge-disclosure-header cp-wt-bold">
        Processing Fee Disclosure
      </h1>
      <h3
        className="cp-mv-8 cp-body cp-wt-semibold"
        style={{ color: "var(--cp-color-app-secondary-text)" }}
      >
        {surchargeFeeText} fee applies to credit cards
      </h3>
      <p
        className="cp-caption cp-mt-0 cp-mb-24"
        style={{ color: "var(--cp-color-app-secondary-text)" }}
      >
        This amount is not greater than the amount paid by the merchant to its
        payment processor for processing the transaction.*
      </p>
      <CpButton block btnType="primary" onClick={closeModal}>
        Ok
      </CpButton>
    </div>
  ) : (
    <CpModal width={424} show={show} onClose={closeModal}>
      <CpModal.Header title="Processing fee disclosure" />
      <CpModal.Body>
        <div className="cp-body-sm">
          {surchargeFeeText} applies to credit cards.
        </div>
        <div
          className="cp-caption"
          style={{
            fontStyle: "italic",
            color: "var(--cp-color-app-secondary-text)",
          }}
        >
          This amount is not greater than what is paid by the merchant for
          processing the transaction.
        </div>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton btnType="primary" onClick={closeModal}>
          Ok
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}

const css = k`
.surcharge-disclosure-overlay {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: var(--cp-color-card-bg);
  z-index: 1000;
}

.surcharge-disclosure-header {
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0;
}
`;
