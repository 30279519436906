import urls from "canopy-urls!sofe";
import { fetchAsObservable } from "fetcher!sofe";
import { tap, pluck } from "rxjs/operators";

export function patchUser(userId, userPatch) {
  return fetchAsObservable(`${urls.getAPIUrl()}/users/${userId}`, {
    method: "PATCH",
    body: {
      users: userPatch,
    },
  }).pipe(pluck("users"));
}
