export const formatNumber = (number, isFax) => {
  if (number.includes("x") && !isFax) {
    const [baseNumber, ext] = number.split("x");
    return `${maskNumber(baseNumber)} ext. ${ext}`;
  } else return maskNumber(number);

  function maskNumber(number) {
    if (number.length === 10) {
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
    } else return number; // what can one do with a phone number > 10 :man-shrugging
  }
};

export const removeExtension = (number) =>
  number.includes("x") ? number.split("x")[0] : number;
