import React, { useEffect } from "react";
import { handleError } from "src/handle-error";
import { CpWell } from "canopy-styleguide!sofe";
import { formatNumber } from "../billing-helpers";
import { paymentSteps, getSurchargeFee } from "./create-edit-payment.helper";

export default function PaymentSummaryCard({
  total,
  step,
  paymentType,
  setSurchargeFee,
  surchargeFee,
  shouldSurcharge,
}) {
  useEffect(() => {
    if (
      shouldSurcharge &&
      step === paymentSteps.PaymentDetails &&
      paymentType
    ) {
      getSurchargeFee({
        amount: total,
        payment_type:
          paymentType === "newCreditCard" || paymentType === "savedCreditCard"
            ? "credit_card"
            : "ach",
      }).subscribe((response) => {
        if (
          paymentType === "newCreditCard" ||
          paymentType === "savedCreditCard"
        ) {
          setSurchargeFee(parseFloat(response.fees));
          return;
        }

        setSurchargeFee();
      }, handleError);
    }
  }, [step, paymentType]);

  return (
    <CpWell level={2} className="payment-summary-card cp-flex-spread cp-mt-24">
      <div>
        <div>Total Payment</div>
        <div className="cps-header cps-wt-semibold">
          <span className="currency-symbol-large">$</span>
          {formatNumber(total, false, 2)}
        </div>
      </div>
    </CpWell>
  );
}
