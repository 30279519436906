import React from "react";
import { Match } from "@reach/router";
import { getBasePath } from "@utils/routing";

export default function FilesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "var(--cps-color-primary)" }}
    >
      <Match path={`${getBasePath()}/files/*`}>
        {(props) =>
          props.match ? (
            <g fillRule="evenodd">
              <path
                fillOpacity=".3"
                fillRule="nonzero"
                d="M4.63595939,24.0830078 C11.2280004,24.0830078 23.6469457,22.0336914 27.0021703,12 C27.6218319,10.1469221 27.2042132,14.6740217 25.7493144,25.5812988 C14.5072252,25.858097 7.59151051,25.858097 5.00217033,25.5812988 C1.5114987,25.2081491 2.55581087,24.0830078 4.63595939,24.0830078 Z"
                transform="matrix(-1 0 0 1 30.002 0)"
              />
              <path d="M26.7817637,26.4271438 C26.4056433,26.7684473 25.9306964,27.0121971 25.3913735,27.1091782 C25.2261065,27.1388966 25.0584707,27.1538462 24.8904942,27.1538462 L5.58230364,27.1538462 C4.22910243,27.1538462 3.06953588,26.1967339 2.82746789,24.8799904 L1.04564971,13.1876828 C0.769021752,11.6829483 1.77815246,10.241333 3.29960623,9.96774486 C3.46487324,9.93802652 3.63250898,9.92307692 3.80048546,9.92307692 L4.37499837,9.92307692 L4.64548985,7.15384615 C4.64548985,4.85222112 6.5182912,3 8.84548985,3 L17.2454899,3 C17.6167929,3 17.9728884,3.1458787 18.2354393,3.40554446 L20.6253888,5.76923077 L25.4454899,5.76923077 C28,5.76923077 29.6454899,7 29.6454899,9.92307692 L29.6346789,10.0948173 L28,24 C28,25.0862601 27.5440289,25.8989764 26.7817637,26.4271438 Z M26.8506015,18.6742248 L27.843653,9.28998837 C27.843653,8.5 27.1315018,7.8359822 26.3110392,7.8359822 L20.3917626,7.8359822 C19.9847541,7.8359822 19.5944157,7.67443058 19.3066172,7.38686685 L16.6868446,4.76923077 L9,4.76923077 C7.5,4.76923077 6.58011712,5.5 6.58011712,7 L6.31391513,9.92307692 L23.1086761,9.92307692 C24.4618773,9.92307692 25.6214438,10.8801892 25.8635118,12.1969326 L26.8506015,18.6742248 Z M2.9224124,12.5480945 L4.65949307,24.7404022 C4.71215304,25.1100146 5.02865014,25.3846154 5.40199502,25.3846154 L25.0260654,25.3846154 C25.4402789,25.3846154 25.7760654,25.0488289 25.7760654,24.6346154 C25.7760654,24.5992204 25.7735597,24.5638697 25.7685673,24.5288285 L24.0314866,12.3365208 C23.9788267,11.9669084 23.6623296,11.6923077 23.2889847,11.6923077 L3.66491435,11.6923077 C3.25070079,11.6923077 2.91491435,12.0280941 2.91491435,12.4423077 C2.91491435,12.4777027 2.91741996,12.5130534 2.9224124,12.5480945 Z" />
            </g>
          ) : (
            <path
              style={{ fill: "var(--cps-color-af)" }}
              fillRule="evenodd"
              d="M26.7817637,26.4271438 C26.4056433,26.7684473 25.9306964,27.0121971 25.3913735,27.1091782 C25.2261065,27.1388966 25.0584707,27.1538462 24.8904942,27.1538462 L5.58230364,27.1538462 C4.22910243,27.1538462 3.06953588,26.1967339 2.82746789,24.8799904 L1.04564971,13.1876828 C0.769021752,11.6829483 1.77815246,10.241333 3.29960623,9.96774486 C3.46487324,9.93802652 3.63250898,9.92307692 3.80048546,9.92307692 L4.37499837,9.92307692 L4.64548985,7.15384615 C4.64548985,4.85222112 6.5182912,3 8.84548985,3 L17.2454899,3 C17.6167929,3 17.9728884,3.1458787 18.2354393,3.40554446 L20.6253888,5.76923077 L25.4454899,5.76923077 C28,5.76923077 29.6454899,7 29.6454899,9.92307692 L29.6346789,10.0948173 L28,24 C28,25.0862601 27.5440289,25.8989764 26.7817637,26.4271438 Z M26.8506015,18.6742248 L27.843653,9.28998837 C27.843653,8.5 27.1315018,7.8359822 26.3110392,7.8359822 L20.3917626,7.8359822 C19.9847541,7.8359822 19.5944157,7.67443058 19.3066172,7.38686685 L16.6868446,4.76923077 L9,4.76923077 C7.5,4.76923077 6.58011712,5.5 6.58011712,7 L6.31391513,9.92307692 L23.1086761,9.92307692 C24.4618773,9.92307692 25.6214438,10.8801892 25.8635118,12.1969326 L26.8506015,18.6742248 Z M2.9224124,12.5480945 L4.65949307,24.7404022 C4.71215304,25.1100146 5.02865014,25.3846154 5.40199502,25.3846154 L25.0260654,25.3846154 C25.4402789,25.3846154 25.7760654,25.0488289 25.7760654,24.6346154 C25.7760654,24.5992204 25.7735597,24.5638697 25.7685673,24.5288285 L24.0314866,12.3365208 C23.9788267,11.9669084 23.6623296,11.6923077 23.2889847,11.6923077 L3.66491435,11.6923077 C3.25070079,11.6923077 2.91491435,12.0280941 2.91491435,12.4423077 C2.91491435,12.4777027 2.91741996,12.5130534 2.9224124,12.5480945 Z"
            />
          )
        }
      </Match>
    </svg>
  );
}
