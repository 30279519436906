import React, { useContext, useEffect } from "react";
import { navigate } from "@reach/router";

import { CpButton } from "canopy-styleguide!sofe";

import { getResponsivePath } from "src/utils/routing";
import { ClientContext } from "src/client-portal.context.js";
import UserMessageCard from "./not-found-pages/user-message-card.component";

const base = `${getResponsivePath()}clients`;

export function PageNotFound() {
  const { selectedClient, setShowNav } = useContext(ClientContext);
  const clientBase = `${base}/${selectedClient?.id || ""}`;

  useEffect(() => setShowNav(), []);

  return (
    <UserMessageCard
      img="es_caution"
      noLeftNav={true}
      text="That page doesn't exist"
      subText="The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."
      cta={
        <CpButton
          btnType="primary"
          onClick={() => setShowNav(true) || navigate(clientBase)}
        >
          Back to home page
        </CpButton>
      }
    />
  );
}
