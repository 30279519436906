import React, { useContext, useEffect } from "react";
import { DateTime } from "luxon";
import { useCss, k } from "kremling";
import { navigate } from "@reach/router";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";
import PageTitle from "@common/page-title.component";
import { TodoDetailsCard } from "../todo-details-card.component";
import { TodoDetailsHeader } from "../todo-details-header.component";
import { renderDate } from "../todo-card.helper";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { ClientContext } from "../../client-portal.context";
import {
  useGetQuestionnaire,
  useGetClientRequest,
} from "./use-get-questionnaire.hook";

export const QuestionnaireWrapper = ({
  location,
  clientId,
  clientRequestId,
  questionnaireId,
}) => {
  const cameFrom = location.state?.cameFrom;
  const { selectedClient, overridePageHeader } = useContext(ClientContext);
  const phoneOnly = useMediaQuery("--phone-only");

  const {
    data: questionnaire,
    isLoading,
    isError,
  } = useGetQuestionnaire(questionnaireId);

  const {
    data: clientRequest,
    isLoading: clientRequestIsLoading,
    isError: clientRequestIsError,
  } = useGetClientRequest(clientRequestId);

  const styles = useCss(css);
  const dueDate = clientRequest?.due_date
    ? DateTime.fromISO(clientRequest?.due_date).toLocaleString(
        DateTime.DATE_SHORT
      )
    : "";
  const sentBy = clientRequest?.first_sent_by_user_full_name;
  const sentAt = clientRequest?.first_sent_at
    ? ` on ${DateTime.fromISO(clientRequest?.first_sent_at).toLocaleString(
        DateTime.DATE_MED
      )}`
    : "";
  const goBackPath = `/m/clients/${selectedClient?.id}${
    cameFrom === "todo" ? "/todo" : ""
  }`;

  useEffect(() => {
    overridePageHeader(
      <>
        <span>
          <CpButton
            aria-label="Back"
            btnType="icon-dark"
            icon="caret-large-left"
            onClick={() => navigate(goBackPath)}
          />
        </span>
        <div
          className="center-text"
          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          <div
            className="cps-subheader-sm"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {questionnaire?.title}
          </div>
          {renderDate({ dueDate })}
        </div>
        <span className="right-text" />
      </>
    );
    return () => overridePageHeader();
  }, [questionnaire]);

  if (isLoading || clientRequestIsLoading) {
    return (
      <div className="cp-mt-40">
        <CpLoader />
      </div>
    );
  }

  if (isError || clientRequestIsError) {
    return (
      <div {...styles} className="cp-mt-40 page-content">
        <div className="body-content-wrapper">
          <div>There was an error loading the questionnaire.</div>
        </div>
      </div>
    );
  }

  return (
    <div {...styles} className="page-content">
      {!phoneOnly && (
        <PageTitle
          left={
            <CpButton
              btnType="tertiary"
              onClick={() => navigate(goBackPath)}
              icon="caret-large-left"
              aria-label="Back"
            >
              Back
            </CpButton>
          }
          center={<span className="title-text">{questionnaire?.title}</span>}
        />
      )}
      <TodoDetailsCard
        headerContent={
          <TodoDetailsHeader
            title={questionnaire?.title}
            dueDate={dueDate}
            iconName="communication-paper-pen"
          />
        }
        bodyContent={
          <div {...styles}>
            <div className="body-content-wrapper">
              <div>{questionnaire?.description}</div>
              <div className="message-sent-by">{`Sent by ${sentBy}${sentAt}`}</div>
            </div>
          </div>
        }
        footerContent={
          <CpButton
            onClick={() =>
              navigate(
                `/m/clients/${clientId}/todo/questionnaire/${questionnaireId}`,
                {
                  state: { cameFrom },
                }
              )
            }
            block
          >
            Open Questionnaire
          </CpButton>
        }
      />
    </div>
  );
};

const css = k`
  .body-content-wrapper {
    padding: 1.6rem;
  }

  .message-sent-by {
    color: var(--cp-color-app-secondary-text);
    font-size: 1.1rem;
  }
`;
