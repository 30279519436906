import { fetcher } from "fetcher!sofe";
import { useQuery, genQueryKey } from "src/react-query";

export const useGetQuestionnaire = (id) => {
  const query = useQuery({
    queryKey: genQueryKey("questionnaires", { id }),
    queryFn: () => fetcher(`/wg/questionnaires/${id}`),
    staleTime: 60 * 1000, // 1 minute
    enabled: !!id,
  });

  return query;
};

export const useGetClientRequest = (id) => {
  const query = useQuery({
    queryKey: genQueryKey("tasks", { id }),
    queryFn: () =>
      fetcher(`/api/client-requests/${id}`).then((res) => res.client_request),
    staleTime: 60 * 1000, // 1 minute
    enabled: !!id,
  });

  return query;
};
