import { useState, useEffect } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";

export function useSetVariantBrandingColors() {
  const settings = useWhiteLabelSettings();
  let color_hex = getComputedStyle(document.documentElement)
    .getPropertyValue("--cps-color-primary")
    .trim();
  // doing the same thing the canopy-styleguide does where we only customize the primary-color value if we are on a branded environment
  if (
    canopyUrls.getBrandingEnvironment() === canopyUrls.WHITELABEL_ENVIRONMENT &&
    settings
  ) {
    color_hex = settings.color_hex;
  }
  useEffect(() => {
    if (color_hex) {
      const brandingColor = color_hex;
      const rgb = hexToRgb(brandingColor);
      document.documentElement.style.setProperty(
        "--cps-color-primary-light",
        `rgb(${rgb.r + 30}, ${rgb.g + 30}, ${rgb.b + 30})`
      );
      document.documentElement.style.setProperty(
        "--cps-color-primary-dark",
        `rgb(${rgb.r - 50}, ${rgb.g - 50}, ${rgb.b - 50})`
      );
      document.documentElement.style.setProperty(
        "--cps-color-primary-extra-light",
        `rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, .2)`
      );
      document.documentElement.style.setProperty(
        "--cps-color-primary-rgb",
        `${rgb.r}, ${rgb.g}, ${rgb.b}`
      );
    }
  }, [color_hex]);

  return settings;
}

export function useWhiteLabelSettings() {
  const [whiteLabelSettings, setSettings] = useState({});
  useEffect(() => {
    const sub = fetchAsObservable(`/wg/white-label-settings`).subscribe(
      (settings) => {
        setSettings(settings);
      }
    );
    return () => sub.unsubscribe();
  }, []);
  return whiteLabelSettings;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
