import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
  queryClient,
  setupQueryKeygen,
} from "fetcher!sofe";

export { useQueryClient, useQuery, useInfiniteQuery, useMutation, queryClient };
export const { genQueryKey, genQueries, invalidateEntity } =
  setupQueryKeygen("client-portal");
