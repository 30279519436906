import React, { useState } from "react";
import { useCss, k } from "kremling";

import { CpButton, CpLoader } from "canopy-styleguide!sofe";

import TodoCard from "./todo-card.component";

export default function TodoList(props) {
  const scope = useCss(css);

  const {
    hideCompleted,
    maxItemsToShow,
    completeTasks,
    incompleteTasks,
    loading,
    cameFrom,
    cameFromCompletedTask,
  } = props;

  const [showCompletedTodos, setShowCompletedTodos] = useState(
    !!cameFromCompletedTask
  );

  return loading ? (
    <CpLoader />
  ) : (
    <div
      {...scope}
      className={`${props.home ? "home" : ""} todo-list-container`}
    >
      <div className="content-over-rounded">
        {incompleteTasks.map((task, index) => {
          if (maxItemsToShow && index >= maxItemsToShow) {
            return null;
          } else {
            return <TodoCard key={task.id} task={task} cameFrom={cameFrom} />;
          }
        })}
        {!hideCompleted && completeTasks.length > 0 && (
          <CpButton
            className="completed-tasks-button cp-mv-24"
            btnType="flat"
            onClick={() => setShowCompletedTodos(!showCompletedTodos)}
          >
            {showCompletedTodos ? "Hide" : "View"} completed tasks
          </CpButton>
        )}
        {!hideCompleted && showCompletedTodos && (
          <>
            {completeTasks.map((task) => (
              <TodoCard key={task.id} task={task} cameFrom={cameFrom} />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

const css = k`
  .home .content-over-rounded {
    padding-bottom: 0px;
  }

  .content-over-rounded {
    position: relative;
    padding-bottom: 56px;
  }

  .completed-tasks-button {
    display: block;
    margin: 0 auto;
  }
`;
