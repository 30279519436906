import React, { useContext } from "react";
import { useLocation } from "@reach/router";
import { useCss } from "kremling";
import { CpIcon } from "canopy-styleguide!sofe";
import { BillingContext } from "src/billing/billing-context";
import { ClientContext } from "src/client-portal.context.js";

export default function MobileHeader(props) {
  const { pathname } = useLocation();
  const { ft_crm } = useContext(ClientContext);
  const { pageHeaderOverride } = useContext(BillingContext);
  const { selectedClient, whiteLabelSettings, setMenu } = props;
  const name = ft_crm
    ? selectedClient?.external_display_name
    : whiteLabelSettings.show_display_name && selectedClient?.display_name
    ? selectedClient.display_name
    : selectedClient?.name || "";

  const scope = useCss(css);

  const getPageTitle = () => {
    if (/settings/.test(pathname)) {
      return "Settings";
    } else if (/todo/.test(pathname)) {
      return "To-do";
    } else if (/billing/.test(pathname)) {
      return "Billing";
    } else if (/files/.test(pathname)) {
      return "Files";
    } else return "Home";
  };

  return (
    <div {...scope} className="mobile-header-menu">
      {pageHeaderOverride || (
        <>
          <CpIcon name="misc-menu" size={40} onClick={() => setMenu(true)} />
          <div className="center-text">
            <div className="cps-subheader-sm ">{getPageTitle()}</div>
            <div className="cps-body-sm cps-ellipsis">{name}</div>
          </div>
          <div></div>
        </>
      )}
    </div>
  );
}

const css = `
  & .mobile-header-menu {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    padding: 5px 15px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background: radial-gradient(at 35% 0%, var(--cps-color-primary-light), var(--cps-color-primary-dark))
  }

  & .mobile-header-menu > * {
    flex: 1;
  }

  & .center-text {
    text-align: center;
    flex: inherit;
    min-width: 0;
    padding: 0 1.6rem;
  }

  & .right-text {
    text-align: right;
  }
`;
