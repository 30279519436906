import React, { useMemo } from "react";
import { CpEmptyState } from "canopy-styleguide!sofe";
import { DateTime } from "luxon";

import TodoList from "./todo-list.component";
import { getRequestStatus } from "./todo.helper";

export function TodoListWrapper({
  tasks,
  loading,
  cameFrom,
  maxItemsToShow,
  hideCompleted,
  cameFromCompletedTask,
  home,
}) {
  const isTaskComplete = (status) => getRequestStatus(status) === "complete";

  const incompleteTasks = useMemo(
    () => (tasks ? tasks.filter((task) => !isTaskComplete(task.status)) : []),
    [tasks]
  );
  const completeTasks = useMemo(
    () =>
      tasks
        ? tasks
            .filter((task) => isTaskComplete(task.status))
            .sort((a, b) =>
              DateTime.fromISO(
                b.client_completed_at || b.practitioner_completed_at
              ).diff(
                DateTime.fromISO(
                  a.client_completed_at || a.practitioner_completed_at
                )
              )
            )
        : [],
    [tasks]
  );

  return (
    <div className="cp-mh-24">
      {(tasks.length === 0 ||
        (tasks.length > 0 && incompleteTasks.length === 0)) && (
        <div className="cp-card-l2 cp-mb-12 cp-p-36">
          <CpEmptyState
            img="es_tasks_complete"
            text={
              tasks.length === 0
                ? "You don't have any tasks assigned to you yet"
                : "All of your tasks have been completed"
            }
          />
        </div>
      )}
      <TodoList
        maxItemsToShow={maxItemsToShow}
        hideCompleted={hideCompleted}
        completeTasks={completeTasks}
        incompleteTasks={incompleteTasks}
        loading={loading}
        cameFrom={cameFrom}
        cameFromCompletedTask={cameFromCompletedTask}
        home={home}
      />
    </div>
  );
}
