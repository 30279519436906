import React from "react";
import { Match } from "@reach/router";
import { getBasePath } from "@utils/routing";

export default function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "var(--cps-color-primary)" }}
    >
      <Match path={`${getBasePath()}`}>
        {(props) =>
          props.match ? (
            <g fillRule="evenodd" transform="translate(3 3)">
              <rect width="6" height="9" x="10" y="15" fillOpacity=".3" />
              <polygon
                fillOpacity=".3"
                points="3 11 3 15 13 6 23 15 23 11 13 2"
              />
              <path d="M12.3242754,0.26284586 C12.7065966,-0.0876152866 13.2934034,-0.0876152866 13.6757246,0.26284586 L13.6757246,0.26284586 L25.6757246,11.2628459 C26.0828436,11.6360383 26.1103465,12.2686056 25.7371541,12.6757246 C25.3639617,13.0828436 24.7313944,13.1103465 24.3242754,12.7371541 L24.3242754,12.7371541 L23.9999941,12.44 L24,23 C24,24.0418587 23.2208823,24.9170285 22.2054363,24.9944368 L22.0588235,25 L3.94117647,25 C2.90445386,25 2.07821238,24.1721874 2.00524151,23.1476349 L2,23 L1.99999411,12.439 L1.67572463,12.7371541 C1.29992248,13.0816394 0.73202231,13.0847039 0.353350799,12.7629268 L0.26284586,12.6757246 C-0.110346548,12.2686056 -0.0828436184,11.6360383 0.324275371,11.2628459 L0.324275371,11.2628459 L4.02799529,7.86709446 C4.00950206,7.79244933 4,7.71543356 4,7.63767211 L4,3.5 C4,3.22385763 4.22385763,3 4.5,3 L6.5,3 C6.77614237,3 7,3.22385763 7,3.5 L6.99999411,5.142 Z M12.9999941,2.35656838 L3.99999411,10.606 L4,23 L9,23 L9,16 C9,14.8954305 9.8954305,14 11,14 L15,14 C16.1045695,14 17,14.8954305 17,16 L17,23 L22,23 L21.9999941,10.607 L12.9999941,2.35656838 Z M15,16 L11,16 L11,23 L15,23 L15,16 Z" />
            </g>
          ) : (
            <path
              style={{ fill: "var(--cps-color-af)" }}
              fillRule="evenodd"
              d="M12.3242754,0.26284586 C12.7065966,-0.0876152866 13.2934034,-0.0876152866 13.6757246,0.26284586 L13.6757246,0.26284586 L25.6757246,11.2628459 C26.0828436,11.6360383 26.1103465,12.2686056 25.7371541,12.6757246 C25.3639617,13.0828436 24.7313944,13.1103465 24.3242754,12.7371541 L24.3242754,12.7371541 L23.9999941,12.44 L24,23 C24,24.0418587 23.2208823,24.9170285 22.2054363,24.9944368 L22.0588235,25 L3.94117647,25 C2.90445386,25 2.07821238,24.1721874 2.00524151,23.1476349 L2,23 L1.99999411,12.439 L1.67572463,12.7371541 C1.29992248,13.0816394 0.73202231,13.0847039 0.353350799,12.7629268 L0.26284586,12.6757246 C-0.110346548,12.2686056 -0.0828436184,11.6360383 0.324275371,11.2628459 L0.324275371,11.2628459 L4.02799529,7.86709446 C4.00950206,7.79244933 4,7.71543356 4,7.63767211 L4,3.5 C4,3.22385763 4.22385763,3 4.5,3 L6.5,3 C6.77614237,3 7,3.22385763 7,3.5 L6.99999411,5.142 Z M12.9999941,2.35656838 L3.99999411,10.606 L4,23 L9,23 L9,16 C9,14.8954305 9.8954305,14 11,14 L15,14 C16.1045695,14 17,14.8954305 17,16 L17,23 L22,23 L21.9999941,10.607 L12.9999941,2.35656838 Z M15,16 L11,16 L11,23 L15,23 L15,16 Z"
              transform="translate(3 3)"
            />
          )
        }
      </Match>
    </svg>
  );
}
