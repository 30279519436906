import React, { useEffect, useState } from "react";
import { useWithUserAndTenant } from "cp-client-auth!sofe";
import { DateTime } from "luxon";

export default function useUserHasAPendingEmailChange(user) {
  let hasChange = false;

  const { pendingEmail, pendingEmailReceived } = user || {};

  if (!user) return false;

  if (pendingEmail && pendingEmailReceived) {
    const twoHoursFromWhenTheChangeWasInitiated = DateTime.fromISO(
      pendingEmailReceived
    ).plus({ hours: 2 });
    const timeNow = DateTime.local();

    hasChange = timeNow <= twoHoursFromWhenTheChangeWasInitiated;
  }

  return hasChange;
}
