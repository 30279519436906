import { DateTime } from "luxon";

export function getRequestStatus(rawStatus) {
  switch (rawStatus) {
    case "WITH_CLIENT":
    case "NOSTATUS":
    case "with_client":
    case "nostatus":
    case "other":
      return "incomplete";
    case "ACTIVE":
    case "REVIEW":
    case "NEEDS_REVIEW":
    case "needs_review":
    case "review":
    case "COMPLETE":
    case "complete":
    case "COMPLETED":
    case "completed":
    case "DONE":
      return "complete";
    default:
      return "incomplete";
  }
}

export function formatFullDate(isoDate) {
  if (!isoDate) return;
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_FULL);
}
