import React, { useEffect, useState } from "react";
import { forkJoin } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";
import { getRequestStatus } from "../../../todo/todo.helper";

export default function useGetClientsTodosCount(clients) {
  const [clientCounts, setClientCounts] = useState();
  // are there any incomplete todos?
  const isOutstanding = (todo) => {
    const status = getRequestStatus(todo.status_id || todo.status);
    return status === "incomplete";
  };

  const createRequestsObs = (id) => {
    return fetchAsObservable(`/api/clients/${id}/requests`).pipe(
      map((response) => {
        const { client_requests = [], task_client_requests = [] } = response;
        return (
          client_requests.filter((cr) => isOutstanding(cr)).length +
          task_client_requests.filter((t) => isOutstanding(t)).length
        );
      })
    );
  };

  const createSurveysObs = (id) => {
    return fetchAsObservable(`/wg/clients/${id}/surveys`).pipe(
      pluck("surveys"),
      map(
        (surveyArr) =>
          surveyArr.filter((sur) => sur.status !== "submitted").length
      )
    );
  };

  const getClientCounts = (id) => {
    return forkJoin(createRequestsObs(id), createSurveysObs(id)).pipe(
      map(([a, b]) => ({ [id]: a + b }))
    ); // sum the counts of both outstanding todo types
  };

  useEffect(() => {
    if (clients?.length) {
      const disposable = forkJoin(
        clients.map((client) => getClientCounts(client.id))
      ).subscribe((res) => {
        const countsByClient = res.reduce((acc, val) => {
          // [{123: 2}, {234: 0}]
          return { ...acc, ...val };
        }, {}); // {123: 2, 234: 0}
        setClientCounts(countsByClient);
      });
      return () => disposable.unsubscribe();
    }
  }, [clients]);

  return clientCounts;
}
