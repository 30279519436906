import React, { useContext } from "react";
import { CpIcon, CpToggle, CpTooltip, CpWell } from "canopy-styleguide!sofe";
import { PaymentContext } from "../billing-context";
import { CurrencyInput } from "src/common/currency-input/currency-input.component";

export default function AdditionalPayment() {
  const { paymentDetails, actions } = useContext(PaymentContext);
  return (
    <CpWell
      className="cp-pv-20 cp-ph-24"
      level={2}
      style={{ borderRadius: "8px" }}
    >
      <div className="cp-flex-spread-center">
        <div className="cp-flex-center">
          <div className="cp-title cp-wt-semibold cp-mr-4">
            Additional Payment
          </div>
          <CpTooltip
            position="top"
            text={
              <div className="cp-text-center">
                This payment will not be applied to any invoices. It will
                automatically create a credit on your account.
              </div>
            }
          >
            <CpIcon
              fill="var(--cp-color-app-icon)"
              name="information-circle-open-small"
            />
          </CpTooltip>
        </div>
        <CpToggle
          checked={paymentDetails.additionalPaymentEnabled}
          onChange={actions.toggleAdditionalPayment}
        />
      </div>
      {paymentDetails.additionalPaymentEnabled && (
        <div className="cp-mt-8">
          <CurrencyInput
            value={paymentDetails.additionalPayment}
            onChange={(newValue) => {
              actions.updateAdditionalPayment(newValue);
            }}
          />
        </div>
      )}
    </CpWell>
  );
}
