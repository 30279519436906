import React, { useContext } from "react";
import { CpIcon, CpNameChip } from "canopy-styleguide!sofe";
import { ClientContext } from "src/client-portal.context.js";

export default function AccountMenuTrigger(props) {
  const {
    toggle,
    activeClient,
    outstandingTodos,
    hasMultipleAccounts,
    showDisplayName,
  } = props;

  const { ft_crm } = useContext(ClientContext);
  const name = ft_crm
    ? activeClient?.external_display_name
    : showDisplayName && activeClient?.display_name
    ? activeClient.display_name
    : activeClient?.name;

  return (
    <div className="dropdownTrigger" onClick={toggle} role="button">
      <div className="cp-flex-center">
        {!ft_crm && <CpNameChip className="cp-mr-16" name={name} />}
        <div className="label">
          <div className="cps-body cps-ellipsis">{name}</div>
          {hasMultipleAccounts && (
            <div className="cps-ellipsis cps-caption">Switch Accounts</div>
          )}
        </div>
      </div>
      <div className="cp-flex-center">
        {outstandingTodos && <div className="dot" />}
        <CpIcon name="caret-small-right" className="cp-mr-4" />
      </div>
    </div>
  );
}
