import { useState, useEffect, useContext } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import { handleError } from "src/handle-error";
import { ClientContext } from "../client-portal.context.js";

export function useTodosApi() {
  const { selectedClient } = useContext(ClientContext);

  const [todoData, setTodoData] = useState({
    loading: true,
    todos: [],
  });

  useEffect(() => {
    const todos$ = fetchAsObservable(
      `/api/clients/${selectedClient.id}/todos`
    ).subscribe((response) => {
      setTodoData({
        loading: false,
        todos: response?.todos || [],
      });
    }, handleError);

    return () => todos$.unsubscribe();
  }, [selectedClient.id]);

  return todoData;
}

export function getEsignFile(fileId, logView) {
  const _logView = logView ? "?log_view=true" : "";
  return fetchAsObservable(`/api/docs/esign-docs/${fileId}${_logView}`)
    .pipe(pluck("esign_doc"))
    .toPromise();
}

export function getResolutionCaseEsign(letterId, esignDocId) {
  return fetchAsObservable(
    `/api/letters/${letterId}/esign_docs/${esignDocId}/signing_requests`
  )
    .pipe(pluck("signing_requests"))
    .toPromise();
}

export function getEngagementPdf(engagementId, userId) {
  return fetchAsObservable(
    `/api/engagements/${engagementId}/signer/${userId}/pdf`
  );
}
