import { useEffect, useRef, useState } from "react";

export const useInputWithDisplayValue = (initialValue, formatter) => {
  const [showInput, setShowInput] = useState(false);
  const [boundValue, setBoundValue] = useState(initialValue);
  const [displayValue, setDisplayValue] = useState("");

  const inputRef = useRef(null);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  useEffect(() => {
    if (!showInput) {
      setDisplayValue(boundValue ? formatter(boundValue) : "");
    }
  }, [showInput, boundValue, formatter]);

  return {
    boundValue,
    setBoundValue,
    displayValue,
    showInput,
    setShowInput,
    inputRef,
  };
};
