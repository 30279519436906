import React, { useContext, useEffect, useState } from "react";
import { forkJoin } from "rxjs";
import { DateTime } from "luxon";
import { navigate } from "@reach/router";
import { handleError } from "src/handle-error";
import { CpButton, CpLoader } from "canopy-styleguide!sofe";
import { useCss, k } from "kremling";
import { TodoDetailsCard } from "../todo-details-card.component";
import { TodoDetailsHeader } from "../todo-details-header.component";
import PageTitle from "@common/page-title.component.js";
import { getClientSurvey, getResolutionCase } from "./surveys.resource";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import { renderDate } from "../todo-card.helper";
import { ClientContext } from "../../client-portal.context";
import { get } from "lodash";

export function SurveyWrapper(props) {
  const cameFrom = get(props, "location.state.cameFrom", "");
  const { resolutionCaseId, surveyId, clientId } = props;
  const [survey, setSurvey] = useState({});
  const [resolutionCase, setResolutionCase] = useState({});
  const [loading, setLoading] = useState(true);
  const { selectedClient, overridePageHeader } = useContext(ClientContext);
  const phoneOnly = useMediaQuery("--phone-only");

  const title = !survey?.surveyType
    ? ""
    : survey?.surveyType === "taxRes"
    ? "Client Survey"
    : `${survey?.surveyData?.returnYear} Client Organizer ${
        survey?.surveyData?.returnType && `(${survey.surveyData.returnType})`
      }`;
  const dueDate = resolutionCase?.due_date
    ? DateTime.fromMillis(resolutionCase?.due_date).toLocaleString(
        DateTime.DATE_MED
      )
    : "";
  const goBackPath = `/m/clients/${selectedClient?.id}${
    cameFrom === "todo" ? "/todo" : ""
  }`;
  useEffect(() => {
    const subscriber = forkJoin([
      getClientSurvey(props),
      getResolutionCase(props),
    ]).subscribe(([surveyResponse, resolutionCaseResponse]) => {
      setSurvey(surveyResponse);
      setResolutionCase(resolutionCaseResponse);
      setLoading(false);
      if (resolutionCaseResponse.is_archived) {
        navigate(`/m/clients/${selectedClient?.id}/todo`, {
          state: { archived: true },
          replace: true,
        });
      }
    }, handleError);
    return () => subscriber.unsubscribe();
  }, [resolutionCaseId, surveyId, clientId]);

  useEffect(() => {
    overridePageHeader(
      <>
        <span>
          <CpButton
            aria-label="Back"
            btnType="icon-dark"
            icon="caret-large-left"
            onClick={() => navigate(goBackPath)}
          />
        </span>
        <div
          className="center-text"
          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          <div
            className="cps-subheader-sm"
            style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {title}
          </div>
          {renderDate({ dueDate })}
        </div>
        <span className="right-text" />
      </>
    );
    return () => overridePageHeader();
  }, [survey, dueDate]);

  const styles = useCss(css);

  return loading ? (
    <CpLoader />
  ) : (
    <div {...styles} className="page-content no-bottom-nav">
      {!phoneOnly && (
        <PageTitle
          left={
            <CpButton
              btnType="tertiary"
              onClick={() => navigate(goBackPath)}
              icon="caret-large-left"
              aria-label="Back"
            >
              Back
            </CpButton>
          }
          center={<span className="title-text">{title}</span>}
        />
      )}
      <TodoDetailsCard
        headerContent={
          <TodoDetailsHeader
            title={title}
            dueDate={dueDate}
            iconName={
              survey?.surveyType === "taxRes"
                ? "communication-clipboard-check"
                : "communication-paper-pen"
            }
          />
        }
        bodyContent={
          <div {...styles}>
            <div className="body-content-wrapper">
              <div>{survey?.notification?.header}</div>
              <div>{survey?.notification?.body}</div>
              <div>{survey?.notification?.closing}</div>
              {!!survey?.sentDate && (
                <div className="message-sent-by">{`Sent by ${
                  resolutionCase?.updated_by
                } on ${DateTime.fromMillis(survey?.sentDate).toLocaleString(
                  DateTime.DATE_MED
                )}`}</div>
              )}
            </div>
          </div>
        }
        footerContent={
          <CpButton
            onClick={() =>
              navigate(
                `/m/clients/${clientId}/todo/survey/${surveyId}/resolution-case/${resolutionCaseId}`,
                {
                  state: { cameFrom },
                }
              )
            }
            block
          >
            {survey?.surveyType === "taxRes" ? "Open Survey" : "Open Organizer"}
          </CpButton>
        }
      />
    </div>
  );
}

const css = k`
  .body-content-wrapper {
    padding: 1.6rem;
  }

  .message-sent-by {
    color: var(--cp-color-app-secondary-text);
    font-size: 1.1rem;
  }
`;
