import React, { useEffect } from "react";
import { CpInput } from "canopy-styleguide!sofe";

import { useInputWithDisplayValue } from "@hooks/use-input-with-display-value.hook";
import { formatNumber } from "src/billing/billing-helpers";

const decimalNumberFilter = (value, precision) => {
  const regExpression = /[^0-9.]+/g;
  var clean = value.replace(regExpression, "");
  const firstDecimal = clean.indexOf(".");

  if (firstDecimal >= 0) {
    //only one decimal point
    let secondDecimal = clean.indexOf(".", firstDecimal + 1);
    if (secondDecimal >= 0) {
      clean = clean.substring(0, secondDecimal);
    } else if (precision > 0) {
      //precision
      const endIndex = Math.min(
        clean.length,
        clean.indexOf(".") + precision + 1
      );
      clean = clean.substring(0, endIndex);
    } else if (precision == 0) {
      clean = clean.substring(0, firstDecimal);
    }
  }

  if (clean === ".") return "0.";
  return clean;
};

export const CurrencyInput = (props) => {
  const {
    boundValue,
    setBoundValue,
    displayValue,
    showInput,
    setShowInput,
    inputRef,
  } = useInputWithDisplayValue(props.value, (value) =>
    formatNumber(value, true, 2)
  );

  useEffect(() => {
    setBoundValue(props.value);
  }, [props.value]);

  return (
    <>
      <CpInput
        aria-label="bound-value"
        ref={inputRef}
        maxLength="11"
        inputMode="decimal"
        style={showInput ? {} : { display: "none" }}
        value={boundValue.toString()}
        onChange={(value) => {
          props.onChange(decimalNumberFilter(value, 2), false);
        }}
        onBlur={() => {
          let value = parseFloat(boundValue) || "";
          setShowInput(false);
          props.onChange(value, true);
        }}
      />
      <CpInput
        aria-label="display-value"
        style={showInput ? { display: "none" } : {}}
        placeholder="$0.00"
        value={displayValue}
        onFocus={() => setShowInput(true)}
      />
    </>
  );
};
