import React from "react";
import { useCss, k, a } from "kremling";
import { CpIcon } from "canopy-styleguide!sofe";
import { renderDate } from "./todo-card.helper";

export function TodoDetailsHeader({
  iconName,
  title,
  dueDate,
  completedDate,
  pendingCompletion,
}) {
  const styles = useCss(css);

  return (
    <div {...styles} className={a("header").m("completed", completedDate)}>
      <div className="cp-mr-8">
        <CpIcon
          name={completedDate ? "checkmark-circle-open-large" : iconName}
        />
      </div>
      <div className="header-title">
        <div className="title">{title}</div>
        {renderDate({ completedDate, pendingCompletion, dueDate })}
      </div>
    </div>
  );
}

const css = k`
  .header {
    padding: 1.6rem;
    background: white;
    display: flex;
    align-items: center;
  }

  .header.completed {
    color: var(--cp-color-priority-disabled-text);
  }

  .header.completed .title {
    text-decoration: line-through;
  }

  .title {
    font-weight: bold;
  }
`;
