import React, { useState, useEffect, useContext, Suspense } from "react";
import {
  CpButton,
  CpCard,
  CpIcon,
  CpLoader,
  CpWell,
  CpCheckbox,
} from "canopy-styleguide!sofe";
import { PaymentFormCC } from "../billing/create-edit-payment/payment-form-cc.component";
import { PaymentFormACH } from "../billing/create-edit-payment/payment-form-ach.component";
import PaymentError from "../billing/create-edit-payment/payment-error.component";
import { ConfirmDeleteModal } from "./confirm-delete-modal.component";
import { useMediaQuery } from "@hooks/use-media-query.hook";
import {
  getPaymentMethodName,
  isValidPayment,
  paymentSteps,
  AdyenPaymentForm,
  paymentViewMode,
} from "../billing/create-edit-payment/create-edit-payment.helper";
import { BillingContext, PaymentContext } from "../billing/billing-context";
import { ClientContext } from "src/client-portal.context";

export const CreateEditPaymentMethod = () => {
  const { tenant, overridePageHeader } = useContext(ClientContext);
  const { hasAdyen } = useContext(BillingContext);
  const { step, paymentDetails, actions, status, paysafeInstance } =
    useContext(PaymentContext);
  const {
    setAdyenInstance,
    setSavedMethod,
    toggleMakeDefault,
    setSavedMethodName,
  } = actions;
  const {
    paymentMethod,
    isFuturePayment,
    adyenSessionDetails,
    adyenInstance,
    savedMethod,
    makeDefault,
    savedMethodName,
  } = paymentDetails;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const phoneOnly = useMediaQuery("--phone-only");

  const cc = paymentMethod.type.includes("CreditCard");
  const isEdit = paymentMethod.type.includes("saved");
  const validCardType = cc ? paymentDetails.creditCardData.validCardType : true;
  const validNickname =
    savedMethodName?.trim().length || paymentMethod?.nickname?.trim().length;

  useEffect(() => {
    overridePageHeader(
      <>
        <div>
          <CpButton
            aria-label="Back"
            btnType="icon-dark"
            icon="caret-large-left"
            onClick={onCancel}
          />
        </div>
        <span className="cps-subheader-sm center-text">
          {isEdit ? "Edit" : "Add a"} {cc ? "Credit Card" : "Bank Account"}
        </span>
        <span className="right-text">
          <CpButton
            aria-label="Close"
            btnType="icon-dark"
            icon="close-large"
            onClick={onCancel}
          />
        </span>
      </>
    );
    return () => overridePageHeader();
  }, []);

  const onCancel = () => {
    actions.resetPaymentMethod(true);
    hasAdyen &&
      paymentDetails.adyenSessionDetails &&
      actions.setAdyenSessionDetails();
  };

  const onClose = () => {
    actions.reset(0, actions.refetchPaymentMethods);
    setLoading(false);
  };

  const onConfirmDelete = () => {
    setLoading(true);
    actions.deletePaymentMethod(onClose);
  };

  const handleNicknameChanged = (value) => {
    const nickname = value.trimLeft();
    actions.updateNickname(nickname);
    actions.setSavedMethodName(nickname);
  };

  return (
    <>
      <CpCard level={phoneOnly ? 0 : 2}>
        {!phoneOnly && (
          <CpCard.Header style={{ background: "var(--cp-color-well-l2-bg)" }}>
            <div className="cp-flex-center">
              <CpIcon
                className="cp-mr-16"
                name={cc ? "billing-credit-card" : "billing-check"}
              />
              {isEdit ? (
                <div>{getPaymentMethodName(paymentMethod)}</div>
              ) : (
                <span>Add a {cc ? "credit card" : "bank account"}</span>
              )}
            </div>
            <CpButton
              aria-label="Close"
              btnType="icon"
              icon="caret-large-up"
              onClick={onCancel}
            />
          </CpCard.Header>
        )}
        <CpCard.Body>
          <>
            {hasAdyen && paymentDetails.adyenSessionDetails && (
              <Suspense fallback={<CpLoader />}>
                <AdyenPaymentForm
                  paymentMethod={paymentMethod}
                  paymentType={paymentMethod.type}
                  adyenSessionDetails={adyenSessionDetails}
                  setAdyenInstance={setAdyenInstance}
                  isFuturePayment={isFuturePayment}
                  savedMethod={savedMethod}
                  setSavedMethod={setSavedMethod}
                  savedMethodName={paymentMethod.nickname}
                  setSavedMethodName={handleNicknameChanged}
                  defaultMethod={makeDefault}
                  setDefaultMethod={toggleMakeDefault}
                  paymentViewMode={paymentViewMode.Settings}
                  isInternal={tenant.is_internal}
                />
                <div className="cp-mt-24">
                  <CpWell level={3} className="cp-p-16">
                    <CpCheckbox
                      onChange={(isChecked) => actions.authorizeUse(isChecked)}
                      checked={paymentMethod.authorizedUse}
                    >
                      <div className="cp-ml-12">
                        {`I authorize ${tenant.company_name} to use the ${
                          cc ? "credit card" : "bank account"
                        } information above for future transactions. I understand that the ${
                          cc ? "credit card" : "banking information"
                        } provided above will be saved.`}
                      </div>
                    </CpCheckbox>
                  </CpWell>
                </div>
              </Suspense>
            )}
            {!hasAdyen &&
              (cc ? (
                <PaymentFormCC forceSave={true} />
              ) : (
                <PaymentFormACH forceSave={true} />
              ))}
            {step === paymentSteps.Error && (
              <PaymentError error={status.paymentError} />
            )}
          </>
        </CpCard.Body>
        <CpCard.Footer>
          <div className="cp-flex-spread">
            <div>
              <CpButton
                btnType="primary"
                className="cp-mr-8"
                onClick={() => {
                  setLoading(true);
                  actions.savePaymentMethod(onClose, () => setLoading(false));
                }}
                showLoader={loading}
                disabled={
                  (!hasAdyen &&
                    !isValidPayment(paymentDetails, paysafeInstance)) ||
                  !validCardType ||
                  (hasAdyen && !adyenInstance) ||
                  (hasAdyen && !validNickname)
                }
              >
                Save {cc ? "card" : "account"}
              </CpButton>
              <CpButton btnType="tertiary" onClick={onCancel}>
                Cancel
              </CpButton>
            </div>
            {isEdit && (
              <CpButton
                aria-label="Delete"
                btnType="icon"
                icon="crud-trash-large"
                onClick={() => setShowConfirmDeleteModal(true)}
              />
            )}
          </div>
        </CpCard.Footer>
      </CpCard>
      <ConfirmDeleteModal
        show={showConfirmDeleteModal}
        paymentType={cc ? "card" : "ach"}
        canDelete={
          paymentMethod.can_delete !== false &&
          paymentMethod.payments?.length === 0
        }
        onCancel={() => setShowConfirmDeleteModal(false)}
        onConfirm={onConfirmDelete}
        loading={loading}
      />
    </>
  );
};
