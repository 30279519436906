import { useState, useEffect, useMemo } from "react";
import { orderBy } from "lodash";
import { EMPTY, forkJoin, of } from "rxjs";
import { map, pluck } from "rxjs/operators";
import { useObservable, fetchAsObservable } from "fetcher!sofe";

export const allFilter = {
  due_date: { order: "asc" },
  draft: {
    filter_params: false,
  },
};

export const outstandingFilter = {
  due_date: { order: "asc" },
  status: {
    filter_params: [
      "Current",
      "1-30 days past due",
      "31-60 days past due",
      "61-90 days past due",
      "91+ days past due",
    ],
  },
  draft: {
    filter_params: false,
  },
};

export const useCanopyPayments = (override) => {
  const [hasCanopyPayments, setHasCanopyPayments] = useState(null);
  const [amexEnabled, setAmexEnabled] = useState(null);
  const [hasPaysafe, setHasPaysafe] = useState(null);
  const [hasAdyen, setHasAdyen] = useState(null);
  const [surchargeEnabled, setSurchargeEnabled] = useState(null);
  const [surchargeViewed, setSurchargeViewed] = useState(null);
  const [surchargeFeeText, setSurchargeFeeText] = useState(null);

  useEffect(() => {
    if (override !== undefined) {
      setHasCanopyPayments(override);
    } else {
      getPaymentAccountDetails().then((response) => {
        setHasCanopyPayments(response.isActive);
        setAmexEnabled(response.amexEnabled);
        setHasPaysafe(response.hasPaysafe);
        setHasAdyen(response.hasAdyen);
        setSurchargeEnabled(response.surcharge);
        setSurchargeViewed(response.surcharge && response.surchargeViewed);
        setSurchargeFeeText(response.surchargeFee);
      });
    }
  }, []);

  return {
    hasCanopyPayments,
    amexEnabled,
    hasPaysafe,
    hasAdyen,
    surchargeEnabled,
    surchargeViewed,
    surchargeFeeText,
  };
};

const getPaymentAccountDetails = () => {
  return new Promise((resolve) => {
    fetchAsObservable("/api/canopy_payments/status").subscribe(
      (response) => {
        const status = response.status;
        resolve({
          isActive: status.is_active,
          amexEnabled:
            !status.amex_opt_out && status.amex_submitted_status === "APPROVED",
          paysafeActive: status.is_paysafe,
          hasPaysafe: status.has_paysafe,
          hasAdyen: status.has_adyen,
          surcharge: status.surcharge,
          surchargeViewed: status.surcharge_viewed,
          surchargeFee: status.surcharge_fee,
        });
      },
      () => {
        resolve(false);
      }
    );
  });
};

export function useClientUsers(clientId) {
  const obs = useMemo(
    () =>
      fetchAsObservable(`/api/clients/${clientId}/users-and-admins`).pipe(
        pluck("users")
      ),
    [clientId]
  );

  return useObservable(obs, { initialResults: [] });
}

export const useBalance = (
  clientId,
  tenantBillingEnabled,
  hasBillingPermissions
) => {
  const obs = useMemo(() => {
    if (!clientId || !tenantBillingEnabled || !hasBillingPermissions)
      return of(0);
    return fetchAsObservable(
      `/api/dashboard/invoices/outstanding?client_id=${clientId}`
    );
  }, [clientId, tenantBillingEnabled, hasBillingPermissions]);

  return useObservable(obs, { initialResults: 0 });
};

export const useInvoices = (clientId, includePaid = false) => {
  const obs = useMemo(() => {
    return fetchAsObservable(
      `/api/clients/${clientId}/invoices:search?limit=999999&page=1`,
      {
        method: "POST",
        body: includePaid ? allFilter : outstandingFilter,
      }
    ).pipe(
      pluck("invoices"),
      map((invoices) => ({
        invoices: invoices.filter((invoice) => invoice.status !== "paid"),
        paidInvoices: invoices.filter((invoice) => invoice.status === "paid"),
      }))
    );
  }, [clientId, includePaid]);

  return useObservable(obs, {
    initialResults: { invoices: [], paidInvoices: [] },
  });
};

export function usePaymentHistory(clientId) {
  const obs = useMemo(() => {
    const payments = fetchAsObservable(
      "/api/payments:search?limit=999999999&page=1",
      {
        method: "POST",
        body: { client_id: { filter_params: [clientId] } },
      }
    ).pipe(pluck("payments"));

    const refunds = fetchAsObservable(
      "/api/payments:search?limit=999999999&page=1",
      {
        method: "POST",
        body: { client_id: { filter_params: [clientId] }, refunds_only: true },
      }
    ).pipe(pluck("payments"));

    return forkJoin({ payments, refunds }).pipe(
      map(({ payments, refunds }) => {
        return orderBy([...payments, ...refunds], "date", "desc");
      })
    );
  }, [clientId]);

  return useObservable(obs, { initialResults: [] });
}

export function useUpcomingPayments(clientId) {
  const obs = useMemo(() => {
    return fetchAsObservable(
      "/api/payments/recurrences:search?scheduled_only=true&limit=999999999&page=1",
      {
        method: "POST",
        body: { client_name: { filter_params: [clientId] } },
      }
    ).pipe(pluck("recurrences"));
  }, [clientId]);

  return useObservable(obs, { initialResults: null });
}

export function useScheduledPayment(paymentId) {
  const obs = useMemo(() => {
    if (paymentId) {
      return fetchAsObservable(`/api/payments/recurrences/${paymentId}`).pipe(
        pluck("recurrences")
      );
    } else {
      return EMPTY;
    }
  }, [paymentId]);

  return useObservable(obs, { initialResults: {} });
}

export function useCredits(clientId) {
  const obs = useMemo(() => {
    return fetchAsObservable(
      `/api/clients/${clientId}/credits?limit=999999999&page=1`
    ).pipe(pluck("credits"));
  }, [clientId]);

  return useObservable(obs, { initialResults: null });
}
