import React from "react";
import { DateTime } from "luxon";
import { ScheduledPaymentReconciler } from "./scheduled-payment-reconciler.component";
import { formatNumber } from "../billing-helpers";
import { getPaymentMethodName } from "./create-edit-payment.helper";

const ConfirmDetails = ({ paymentDetails, surchargeFeeText, tenant }) => {
  const selectedInvoices = paymentDetails.invoices.filter(
    (invoice) => invoice.selected
  );
  const scheduled =
    paymentDetails.isFuturePayment || paymentDetails.isScheduledPayment;

  return (
    <div className="confirm-details">
      <div className="cp-m-24">
        <div>
          <div className="cp-color-app-secondary-text">
            Total payment amount:
          </div>
          <div className="cps-header">
            <span className="currency-symbol-large">$</span>
            {formatNumber(paymentDetails.paymentTotal, false, 2)}
          </div>
        </div>
        {paymentDetails.shouldSurcharge &&
          (paymentDetails?.paymentMethod?.type === "newCreditCard" ||
            paymentDetails?.paymentMethod?.type === "savedCreditCard") && (
            <div className="cp-mt-16 flexwrap">
              <div className="cp-mr-24" style={{ fontStyle: "italic" }}>
                <div className="cp-wt-semibold">
                  A {surchargeFeeText} processing fee equaling $
                  {`${formatNumber(
                    paymentDetails.surchargeFee,
                    false,
                    2
                  )} will be added when using a Credit Card`}
                </div>
                <div
                  className="cp-caption"
                  style={{
                    fontStyle: "italic",
                    color: "var(--cp-color-app-secondary-text)",
                  }}
                >
                  This amount is not greater than the amount paid by the
                  merchant for processing the transaction.
                </div>
              </div>
            </div>
          )}
        <div className="cp-mt-32 flexwrap">
          <div className="cp-mr-24">
            <div className="cp-color-app-secondary-text">Payment date:</div>
            <div className="cps-body cps-wt-semibold">
              {paymentDetails.paymentDate.toLocaleString(DateTime.DATE_MED)}
            </div>
          </div>
          {selectedInvoices.length > 0 && (
            <div className="cp-mr-24">
              <div className="cp-color-app-secondary-text">{`Invoice${
                selectedInvoices.length !== 1 ? "s" : ""
              } paid:`}</div>
              <div className="cps-body cps-wt-semibold">
                {selectedInvoices
                  .map((invoice) => invoice.invoice_number)
                  .join(", ")}
              </div>
            </div>
          )}
          <div className="cp-mr-24">
            <div className="cp-color-app-secondary-text">Payment method:</div>
            <div className="cps-body cps-wt-semibold">
              {getPaymentMethodName(paymentDetails.paymentMethod)}
            </div>
          </div>
        </div>
      </div>
      <ScheduledPaymentReconciler
        selectedInvoices={selectedInvoices}
        paymentId={paymentDetails.paymentId}
      />
      <div className="cp-mt-24" style={{ fontStyle: "italic" }}>
        By clicking {scheduled ? "Schedule payment" : "Confirm and pay"}, you
        authorize {tenant.company_name} to schedule and initiate the payment
        listed above.
      </div>
    </div>
  );
};

export default ConfirmDetails;
