import React, { lazy, Suspense } from "react";
import { Router } from "@reach/router";
const Files = lazy(() =>
  import(/* webpackChunkName: "filesRoot" */ "./files.component.js")
);
// const PreviewSwitcher = lazy(() =>
//   import(/* webpackChunkName: "preview-switcher" */ './preview/preview-switcher.component.js')
// );

export function FilesRouter(props) {
  return (
    <Suspense fallback={null}>
      <Router>
        <Files path="/" />
        <Files path=":folderId" />
        {/*<PreviewSwitcher path="preview/:fileId" />*/}
      </Router>
    </Suspense>
  );
}
