import React, { useState, useContext, useMemo } from "react";
import { useCss } from "kremling";
import { CpDropdown } from "canopy-styleguide!sofe";
import AccountMenuTrigger from "./account-menu-dropdown/account-menu-trigger.component";
import AccountMenuContent from "./account-menu-dropdown/account-menu-content.component";
import useGetClientsTodosCount from "./account-menu-dropdown/use-get-clients-todos-count";
import { ClientContext } from "../../client-portal.context";
import { BillingContext } from "../../billing/billing-context";

import css from "./account-menu.krem.css";
import { SwitchUserMenuContent } from "./account-menu-dropdown/switch-user-menu-content.component";

export default function AccountMenu(props) {
  const { customLinks, companyWebsite, enableBilling, showDisplayName } = props;
  const [showUserMenu, setShowUserMenu] = useState(false);
  const {
    user,
    selectedClient: activeClient,
    clients: clients,
    entities,
  } = useContext(ClientContext);
  const { hasCanopyPayments } = useContext(BillingContext);
  const excludeActive = useMemo(
    () => clients?.filter((c) => c.id !== activeClient?.id),
    [clients, activeClient]
  );
  const clientsTodos = useGetClientsTodosCount(
    activeClient ? excludeActive : []
  );
  const outstandingTodos =
    clientsTodos && Object.keys(clientsTodos).some((key) => clientsTodos[key]);

  return (
    <div {...useCss(css)}>
      {clients && (
        <CpDropdown
          allowContentClicks
          appendTo="parent"
          contentHeight={300}
          contentWidth={375}
          position="right-start"
          renderWhenClosed={false}
          renderTrigger={({ toggle }) => (
            <div>
              <AccountMenuTrigger
                activeClient={activeClient}
                hasMultipleAccounts={
                  entities?.length > 1 || clients?.length > 1
                }
                outstandingTodos={outstandingTodos}
                showDisplayName={showDisplayName}
                toggle={toggle}
              />
            </div>
          )}
          renderContent={({ close }) =>
            showUserMenu ? (
              <SwitchUserMenuContent
                closeUserMenu={() => setShowUserMenu(false)}
                entities={entities}
                user={user}
              />
            ) : (
              <AccountMenuContent
                activeClient={activeClient}
                clients={clients}
                clientsTodos={clientsTodos}
                closeMenu={close}
                companyWebsite={companyWebsite}
                customLinks={customLinks}
                enableBilling={enableBilling}
                entities={entities}
                hasCanopyPayments={hasCanopyPayments}
                showDisplayName={showDisplayName}
                showUserMenu={() => setShowUserMenu(true)}
              />
            )
          }
        />
      )}
    </div>
  );
}
